<template>
    <v-main>
        <v-form ref="form" v-model="valid" lazy-validation>
            <div class="subscribers-table">
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col>
                                <v-icon
                                    class="addUser"
                                    data-id="AddIcon"
                                    size="large"
                                    icon="mdi-account-plus"
                                    @click="addSubscriber()"
                                ></v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div>
                                    {{ `${t('subscriptions.resources.subscribers')}:` }}
                                </div>
                                <v-table>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                {{ t('subscriptions.resources.fullName') }}
                                            </th>
                                            <th scope="col">
                                                {{ t('subscriptions.resources.email') }}
                                            </th>
                                            <th scope="col">
                                                {{ t('subscriptions.resources.phoneNumber') }}
                                            </th>
                                            <th scope="col">
                                                {{ t('subscriptions.resources.subscriptionType') }}
                                            </th>
                                            <th scope="col">
                                                {{ t('subscriptions.resources.messageType') }}
                                            </th>
                                            <th scope="col">
                                                {{ t('shared.resources.common.actions') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, i) in subscribers" :key="i" class="text-left">
                                            <td>
                                                <v-text-field
                                                    v-model="user.fullName"
                                                    :rules="
                                                        user.messageTypes.length > 0 || user.subscriptionTypes.length > 0
                                                            ? [rules.required]
                                                            : []
                                                    "
                                                    color="grey"
                                                    data-id="NameTextField"
                                                >
                                                </v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="user.email"
                                                    :rules="user.emailRules"
                                                    color="grey"
                                                    data-id="EmailTextField"
                                                    validate-on-blur
                                                    @input="updateFormRules(user, 'Email')"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                    v-model="user.phone"
                                                    :rules="user.phoneRules"
                                                    color="grey"
                                                    data-id="PhoneTextField"
                                                    validate-on-blur
                                                    @input="updateFormRules(user, 'SMS')"
                                                ></v-text-field>
                                            </td>
                                            <td class="checkbox">
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.information')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOneText]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="Information"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.warning')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="Warning"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.cancellation')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="Cancellation"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.earlyTripEnd')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="EarlyTripEnd"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.full')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="Full"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.equipmentFailure')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="EquipmentFailure"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.onboardTicketMachine')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="OnboardTicketMachine"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.messageTypes"
                                                    :label="t('messages.resources.atStopOther')"
                                                    :rules="
                                                        user.subscriptionTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOne]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="AtStopOther"
                                                >
                                                </v-checkbox>
                                            </td>
                                            <td class="checkbox">
                                                <v-checkbox
                                                    v-model="user.subscriptionTypes"
                                                    :label="t('subscriptions.resources.email')"
                                                    :rules="
                                                        user.messageTypes.length > 0 || user.fullName.length > 0
                                                            ? [rules.selectOneText]
                                                            : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="Email"
                                                    @update:model-value="updateRules(user)"
                                                >
                                                </v-checkbox>
                                                <v-checkbox
                                                    v-model="user.subscriptionTypes"
                                                    :label="t('subscriptions.resources.sms')"
                                                    :rules="
                                                        user.messageTypes.length > 0 || user.fullName.length > 0 ? [rules.selectOne] : []
                                                    "
                                                    :hide-details="true"
                                                    density="compact"
                                                    color="primary"
                                                    data-id="TypeCheckbox"
                                                    value="SMS"
                                                    @update:model-value="updateRules(user)"
                                                >
                                                </v-checkbox>
                                            </td>
                                            <td>
                                                <v-row>
                                                    <v-col>
                                                        <v-icon
                                                            data-id="DeleteIcon"
                                                            style="outline: none"
                                                            icon="mdi-delete"
                                                            @click="removeSubscriber(i)"
                                                        ></v-icon>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </v-main>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Subscriber } from './subscriptionsModels';

interface SubscriberValidation extends Subscriber {
    emailRules: any[];
    phoneRules: any[];
}

const { t } = useI18n();

const props = defineProps<{
    subscribers: Subscriber[];
}>();
const valid = ref(true);
const rules = {
    required: (v: any) => !!v || t('shared.resources.common.requiredProperty'),
    email: (v: any) => /.+@.+\..+/.test(v) || t('shared.resources.common.invalidEmail'),
    phone: (v: any) => /^\+?\d+$/.test(v) || t('shared.resources.common.invalidPhone'),
    selectOneText: (v: any) => v.length > 0 || t('shared.resources.common.selectOption'),
    selectOne: (v: any) => v.length > 0 || false
};
const subscribers = computed(() => props.subscribers as SubscriberValidation[]);

const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: {
                id: string | number;
                errorMessages: string[];
            }[];
        }>;
        resetValidation: () => void;
    }
>();

function addSubscriber() {
    subscribers.value.unshift({
        id: 0,
        phone: '',
        fullName: '',
        email: '',
        messageTypes: [],
        subscriptionTypes: [],
        emailRules: [],
        phoneRules: []
    });
}

function removeSubscriber(i: number) {
    subscribers.value.splice(i, 1);
}

function updateFormRules(user: any, type: string) {
    const inputField = type === 'Email' ? user.email : user.phone;
    if (inputField.length > 0) {
        user.subscriptionTypes.push(type);
    }
    updateRules(user);
}

function updateRules(user: any) {
    user.phoneRules = user.subscriptionTypes.includes('SMS') ? [rules.required, rules.phone] : [];
    user.emailRules = user.subscriptionTypes.includes('Email') ? [rules.required, rules.email] : [];
    return form.value?.resetValidation();
}

async function validate() {
    return await form.value?.validate();
}

defineExpose({
    validate
});
</script>

<style lang="scss" scoped>
.addUser {
    float: right;
}

.checkbox {
    .v-input {
        margin-top: 0;
    }
}
</style>
