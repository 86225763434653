import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AreasList from '../Features/Areas/AreasList.vue';
import AreasCreate from '../Features/Areas/AreasCreate.vue';
import AreasEdit from '../Features/Areas/AreasEdit.vue';
import CompaniesList from '../Features/Companies/CompaniesList.vue';
import CompanyEdit from '../Features/Companies/CompanyEdit.vue';
import AccessTokensList from '../Features/AccessTokens/AccessTokensList.vue';
import AccessTokenEdit from '../Features/AccessTokens/AccessTokensEdit.vue';
import AccessTokensCreate from '../Features/AccessTokens/AccessTokensCreate.vue';
import DriversHandbooksList from '../Features/DriversHandbooks/DriversHandbooksList.vue';
import DriverHandbooksCreate from '../Features/DriversHandbooks/DriverHandbooksCreate.vue';
import DriverHandbookEdit from '../Features/DriversHandbooks/DriverHandbookEdit.vue';
import SubscriptionsList from '../Features/Subscriptions/SubscriptionsList.vue';
import MessagesList from '../Features/Messages/MessagesList.vue';
import SubscriptionEdit from '../Features/Subscriptions/SubscriptionEdit.vue';
import SubscriptionCreate from '../Features/Subscriptions/SubscriptionCreate.vue';
import MpcStatisticsList from '../Features/MpcStatistics/MpcStatisticsList.vue';
import AccessDeniedPage from '../shared/AccessDeniedPage.vue';
import MessageCreate from '../Features/Messages/MessageCreate.vue';
import MessageEdit from '../Features/Messages/MessageEdit.vue';
import MessagesCreate from '@/Features/DeviationMessages/Form/MessagesCreate.vue';
import DeviationMessagesList from '@/Features/DeviationMessages/List/MessagesList.vue';

export const routeNames = {
    areas: 'getAreas',
    areasEdit: 'AreasEdit',
    companies: 'getCompanies',
    companiesEdit: 'CompanyEdit',
    subscriptions: 'getSubscriptions',
    subscriptionsEdit: 'SubscriptionEdit',
    subscriptionsCreate: 'SubscriptionCreate',
    accessTokens: 'getAccessTokens',
    accessTokensEdit: 'AccessTokensEdit',
    accessTokensCreate: 'AccessTokensCreate',
    driversHandbooks: 'getDriversHandbooks',
    driversHandbooksEdit: 'DriverHandbookEdit',
    driversHandbooksCreate: 'DriverHandbooksCreate',
    messages: 'getMessages',
    messagesEdit: 'MessageEdit',
    messageCreate: 'MessageCreate',
    mpcStatistics: 'getMpcStatistics',
    deviationMessagesCreate: 'deviationMessagesCreate',
    deviationMessages: 'getDeviationMessages'
};

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/messages'
    },
    {
        path: '/areas',
        name: routeNames.areas,
        component: AreasList
    },
    {
        path: '/areas/create',
        name: 'AreasCreate',
        component: AreasCreate
    },
    {
        path: '/areas/:id',
        name: routeNames.areasEdit,
        component: AreasEdit
    },
    {
        path: '/companies',
        name: routeNames.companies,
        component: CompaniesList
    },
    {
        path: '/companies/:id',
        name: routeNames.companiesEdit,
        component: CompanyEdit
    },
    {
        path: '/accesstokens',
        name: routeNames.accessTokens,
        component: AccessTokensList
    },
    {
        path: '/accesstokens/create',
        name: routeNames.accessTokensCreate,
        component: AccessTokensCreate
    },
    {
        path: '/accesstokens/:id',
        name: routeNames.accessTokensEdit,
        component: AccessTokenEdit
    },
    {
        path: '/drivershandbooks',
        name: routeNames.driversHandbooks,
        component: DriversHandbooksList
    },
    {
        path: '/drivershandbooks/create',
        name: routeNames.driversHandbooksCreate,
        component: DriverHandbooksCreate
    },
    {
        path: '/drivershandbooks/:id',
        name: routeNames.driversHandbooksEdit,
        component: DriverHandbookEdit
    },
    {
        path: '/subscriptions',
        name: routeNames.subscriptions,
        component: SubscriptionsList
    },
    {
        path: '/subscriptions/create',
        name: routeNames.subscriptionsCreate,
        component: SubscriptionCreate
    },
    {
        path: '/subscriptions/:id',
        name: routeNames.subscriptionsEdit,
        component: SubscriptionEdit
    },
    {
        path: '/messages',
        name: routeNames.messages,
        component: MessagesList
    },
    {
        path: '/messages/create',
        name: routeNames.messageCreate,
        component: MessageCreate
    },
    {
        path: '/messages/:id',
        name: routeNames.messagesEdit,
        component: MessageEdit
    },
    {
        path: '/mpcstatistics',
        name: routeNames.mpcStatistics,
        component: MpcStatisticsList
    },
    {
        path: '/accessdenied',
        name: 'AccessDenied',
        component: AccessDeniedPage
    },
    {
        path: '/deviation-messages/create',
        name: routeNames.deviationMessagesCreate,
        component: MessagesCreate
    },
    {
        path: '/deviation-messages',
        name: routeNames.deviationMessages,
        component: DeviationMessagesList
    }
] as RouteRecordRaw[];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
