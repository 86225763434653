<template>
    <div class="lines-scope">
        <v-autocomplete
            v-model="modelStops"
            :search="search"
            :items="autocompleteStops"
            :loading="isLoading"
            :placeholder="t('messages.resources.searchStop')"
            append-inner-icon="search"
            multiple
            chips
            closable-chips
            clearable
            return-object
            no-filter
            data-id="NameAutoComplete"
            @update:search="onSearchStops"
            @update:model-value="search = ''"
        >
            <template #item="{ item, props: itemProps }: { item: any, props: any }">
                <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.value}`"></v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { AutocompleteItem } from '@/shared/models';
import { useStopsStore } from '@/Features/Stops/stopsStore';
import { Stop } from '@/Features/Stops/stopsModels';
import { FormSteps } from '../messagesModels';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();
const stopsStore = useStopsStore();
const { t } = useI18n();

const modelStops = ref<AutocompleteItem<Stop>[]>([]);
const isLoading = ref(false);
const search = ref<string | undefined>();

const autocompleteStops = computed<AutocompleteItem<Stop>[]>(() => stopsStore.stops.map(x => toAutocompleteItem(x)));

messagesStore.selectedFormItem = [];

const scopes = messagesStore.formSummary[FormSteps.Scope]?.selectedScopes;
if (scopes?.length) {
    scopes.forEach((scope: any) => {
        modelStops.value.push(scopeToAutocompleteItem(scope));
    });
}

watch(modelStops, (val: AutocompleteItem<Stop>[]) => {
    const selectedStops = val.map((x: AutocompleteItem<Stop>) => getReceiverFormat(x));
    messagesStore.setSummaryScopes(selectedStops);
});

function getReceiverFormat(item: AutocompleteItem<Stop>) {
    return {
        id: item.value,
        name: item.data.name,
        fullName: item.data.fullName,
        publicCode: item.data.publicCode
    };
}

async function onSearchStops(query: string) {
    if (!query) {
        return;
    }

    isLoading.value = true;
    search.value = query;
    await stopsStore.searchStops(query);
    isLoading.value = false;
}

function scopeToAutocompleteItem(scope: any) {
    return { title: scope.fullName, value: scope.id, data: scope };
}

function toAutocompleteItem(stop: Stop) {
    return { title: `${stop.fullName} (${stop.id})`, value: stop.id, data: stop };
}
</script>
