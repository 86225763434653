<template>
    <div class="summary-container">
        <template v-if="hasDepartures">
            <div v-for="(group, i) in groupedDepartures" :key="i" class="departure-group">
                <div class="departure-group-header">
                    <h4 class="departure-group-title">
                        {{ t('messages.resources.departureScopeSummaryTitle', 'Valgt avgang (driftsdøgn)') }}
                    </h4>
                    <span class="departure-group-date">{{ FormatDate(group.operatingDay) }}</span>
                </div>
                <div class="departure-content">
                    <div v-for="(variant, ii) in group.variants" :key="ii" class="departure-variant">
                        <div class="departure-variant-header">
                            <div class="departure-variant-line">{{ variant.lineName }}</div>
                            <div class="departure-variant-name">{{ variant.departure }} - {{ variant.destination }}</div>
                        </div>
                        <div class="departure-variant-list">
                            <v-hover v-for="(departure, iii) in variant.departures" :key="iii" v-slot="{ isHovering, props: itemProps }">
                                <div
                                    v-bind="itemProps"
                                    :class="`elevation-${isHovering ? 6 : 3}`"
                                    class="departure-variant-list-item transition-swing"
                                    @click="emit('removeDeparture', departure)"
                                >
                                    {{ ToLocalDate(departure.departureTime, 'time') }}
                                    <v-icon icon="mdi-close-circle-outline" size="small"></v-icon>
                                    <v-tooltip class="trip-id-tooltip" activator="parent" location="top">
                                        {{ `Trip ID: ${departure.id}` }}
                                    </v-tooltip>
                                </div>
                            </v-hover>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="summary-nodata">
            {{
                t('messages.resources.departureScopeSummaryNoDepartures', 'Velg rute i feltet til høyre og finn korrekt avgang i tabellen.')
            }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { FormatDate, ToLocalDate } from '@/shared/DateFormatHelpers';
import { DepartureReceiver } from '../../messagesModels';
import { groupDeparturesByOperatingDay } from '../../helpers';
import { computed } from 'vue';

const { t } = useI18n();

interface Props {
    departures: DepartureReceiver[];
}

const emit = defineEmits<{
    (e: 'removeDeparture', departure: DepartureReceiver);
}>();

const props = withDefaults(defineProps<Props>(), {
    departures: () => new Array<DepartureReceiver>()
});

const groupedDepartures = computed(() => groupDeparturesByOperatingDay(props.departures));
const hasDepartures = computed(() => groupedDepartures.value.length > 0);
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.summary-container > .departure-group + .departure-group {
    border-top: 1px solid $borderGrey;
    padding-top: 16px;
}

.summary-nodata {
    font-size: 14px;
}

.departure-group-header {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;

    .departure-group-title {
        margin-right: 8px;
    }
}

.departure-variant {
    display: flex;
    flex-direction: column;
    padding: 8px 0 16px 0;

    .departure-variant-header {
        display: flex;
        align-items: center;

        .departure-variant-line {
            font-size: 14px;
            font-weight: bold;
            color: $lightGrey;
            margin-right: 8px;
            background-color: $black;
            width: 30px;
            height: 24px;
            padding-top: 1px;
            vertical-align: middle;
            text-align: center;
            border-radius: 3px;
        }

        .departure-variant-name {
            font-size: 14px;
        }
    }

    .departure-variant-list {
        padding-top: 8px;

        .departure-variant-list-item {
            display: inline-block;
            width: 72px;
            height: 24px;
            margin-right: 8px;
            margin-bottom: 8px;
            font-size: 14px;
            background-color: $white;
            border-radius: 48px;
            padding-top: 1px;
            text-align: center;
            cursor: pointer;

            i.v-icon {
                color: $iconGrey;
                margin-bottom: 1px;
            }
        }
    }
}

.trip-id-tooltip {
    :deep(.v-overlay__content) {
        background-color: $white;
        color: $black;
        box-shadow: 0 8px 16px 0 #0000001a, 0 10px 10px 0 #0000001a;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -8px;
            border-width: 8px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
        }
    }
}
</style>
