<template>
    <div>
        <v-text-field
            v-model="inputField"
            :data-id="`Input${item.name}`"
            :label="item.label"
            :min="item.inputType === 'Number' ? 0 : null"
            :type="item.inputType"
            variant="outlined"
            step="1"
            @input="handleInput"
            @keydown="checkKey"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { FormSteps } from '../messagesModels';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();

const props = defineProps<{
    item: any;
}>();
const emit = defineEmits(['input']);
const inputField = ref(null);
const reasonStep = computed(() => messagesStore.formSummary[FormSteps.Reason]);

const existingInput = reasonStep.value.input;
if (existingInput?.[props.item.name]) {
    inputField.value = existingInput[props.item.name];
}

function checkKey(e: any) {
    if (props.item.inputType !== 'Number') return;
    if (e.charCode === 43 || e.charCode === 45 || e.charCode === 46 || e.charCode === 101) {
        e.preventDefault();
    }
}

function handleInput() {
    emit('input', { name: props.item.name, value: inputField.value, inputType: props.item.inputType });
}
</script>
