<template>
    <div class="operator-scope">
        <v-autocomplete
            v-model="modelCompanies"
            v-model:search-input="search"
            :items="autocompleteCompanies"
            :placeholder="t('messages.resources.searchCompany')"
            append-inner-icon="search"
            multiple
            chips
            closable-chips
            clearable
            return-object
            data-id="NameAutoComplete"
            @update:model-value="search = ''"
        >
            <template #item="{ item, props: itemProps }: { item: any, props: any }">
                <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.data.companyNumber}`"></v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { AutocompleteItem } from '@/shared/models';
import { useCompaniesStore } from '@/Features/Companies/companiesStore';
import { Company } from '@/Features/Companies/companiesModels';
import { toCompanyAutocompleteItem } from '@/Features/Companies/companyHelpers';
import { FormSteps } from '../messagesModels';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();
const companiesStore = useCompaniesStore();
const { t } = useI18n();

const modelCompanies = ref<AutocompleteItem<Company>[]>([]);
const search = ref<string | null>(null);
const autocompleteCompanies = computed<AutocompleteItem<Company>[]>(() => companiesStore.companies.map(x => toCompanyAutocompleteItem(x)));

messagesStore.selectedFormItem = [];
if (autocompleteCompanies.value.length === 0) {
    await companiesStore.loadCompanies();
}
const scopes = messagesStore.formSummary[FormSteps.Scope]?.selectedScopes;
if (scopes?.length) {
    scopes.forEach((scope: any) => {
        const selectedItem = autocompleteCompanies.value.find(item => {
            return item.data.companyNumber === scope.id;
        });
        if (selectedItem) {
            modelCompanies.value.push(selectedItem);
        }
    });
}

watch(modelCompanies, val => {
    if (!val) return;
    const selectedOperators = val.map(x => {
        return getReceiverFormat(x);
    });

    messagesStore.setSummaryScopes(selectedOperators);
});

function getReceiverFormat(item: AutocompleteItem<Company>) {
    return {
        id: item.data.companyNumber,
        name: item.data.name
    };
}
</script>
