import { DefaultPageSize, Link, ListResource, Paging, Resource } from '@/shared/models';
import { defineStore } from 'pinia';
import {
    Message,
    MessageCategoryTemplate,
    MessageFormInstepContent,
    MessageListItem,
    MessageTemplate,
    FormSteps,
    Receiver,
    ServiceJourney
} from './messagesModels';
import { MessagesRels } from './rels';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import { getLink } from '@/shared/LinkHelper';

export const useMessagesStore = defineStore('messages', {
    state: () => ({
        messages: new Array<MessageListItem>(),
        message: {} as Message,
        paging: {} as Paging,
        sourceSystem: 'TicketingMachine',
        query: '',
        newMessageStatus: false,
        preselectedTarget: '',
        categoryTemplates: new Array<MessageCategoryTemplate>(),
        messageTemplates: new Array<MessageTemplate>(),
        serviceJourneys: new Array<ServiceJourney>(),
        currentStep: 0,
        selectedFormItem: null as any | null,
        instepContent: {} as MessageFormInstepContent,
        formSummary: new Array<any>(),
        links: new Array<Link>()
    }),
    getters: {
        formMessageText: state => state.formSummary[FormSteps.Reason]?.message || '',
        categoryMessageTemplates(state) {
            const category = state.formSummary[FormSteps.Categories]?.name;
            return state.messageTemplates.filter(template => {
                return template.categoryName === category;
            });
        }
    },
    actions: {
        async loadMessages(page: number, id?: string, groupId?: string) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getMessages);

            const pageSize = DefaultPageSize;
            const requestParams = new URLSearchParams();
            requestParams.append('page.number', page.toString());
            requestParams.append('page.size', pageSize.toString());
            requestParams.append('sourceSystem', this.sourceSystem);

            if (this.query) {
                requestParams.append('query', this.query);
            }

            if (id) {
                requestParams.append('id', id.toString());
            }

            if (groupId) {
                requestParams.append('groupId', groupId.toString());
            }

            const response = await axios.requestByLink<ListResource<MessageListItem>>(link, { params: requestParams });

            this.messages = response.data.data;
            this.links = response.data.links;
            this.paging = response.data.paging ?? ({} as Paging);
        },
        async loadMessage(id: string) {
            const axios = useAxios();

            await this.loadMessages(1, id);

            const item = this.messages.find(x => x.id == id);
            if (!item) throw Error(`Can't find message by id: ${id}`);

            const link = getLink(MessagesRels.getMessage, item.links);

            const response = await axios.requestByLink<Resource<Message>>(link);

            this.message = response.data.data ?? ({} as Message);
            this.links = response.data.links;
        },
        async initMessage() {
            await this.loadMessages(1);

            this.resetForm(0);
            this.message = {} as Message;
        },
        async createMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.createMessage, this.links);

            const response = await axios.requestByLink<{ groupId: string }>(link, { data: this.message });
            const groupId = response.data.groupId;
            await this.loadMessages(1, undefined, groupId);
            return groupId;
        },
        async updateMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.updateMessage, this.links);

            await axios.requestByLink(link, { data: this.message });
        },
        async publishMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.publishMessage, this.links);

            await axios.requestByLink(link);
        },
        async unpublishMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.unpublishMessage, this.links);

            await axios.requestByLink(link);
        },
        async publishMessageGroup(groupId: string) {
            const axios = useAxios();
            const link = getLink(MessagesRels.publishMessageGroup, this.links);

            await axios.requestByLink(link, { data: { groupId: groupId } });
        },
        async deleteMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.deleteMessage, this.links);

            await axios.requestByLink(link);
        },
        async loadMessageCategories() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getMessageCategories);

            const response = await axios.requestByLink<ListResource<MessageCategoryTemplate>>(link);
            this.categoryTemplates = response.data.data;
        },
        async loadMessageTemplates() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getMessageTemplates);

            const response = await axios.requestByLink<ListResource<MessageTemplate>>(link);
            this.messageTemplates = response.data.data;
        },
        async loadServiceJourneys({ date, linePrivateCode }: { date: string; linePrivateCode: string }) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getServiceJourneys);

            const requestParams = new URLSearchParams();
            requestParams.append('date', date);
            requestParams.append('linePrivateCode', linePrivateCode);

            const response = await axios.requestByLink<ListResource<ServiceJourney>>(link, { params: requestParams });

            this.serviceJourneys = response.data.data;
        },
        getMessageTemplateById(id: string) {
            return this.messageTemplates.find(template => template.id === id);
        },
        resetForm(step: number) {
            this.selectedFormItem = null;
            this.formSummary = this.formSummary.slice(0, step);
            this.currentStep = step;
            this.instepContent = {} as MessageFormInstepContent;
        },
        setFormSummaryByStep({ item, step }: { item: any; step: FormSteps }) {
            this.formSummary[step] = item;
        },
        setFormSummaryByCurrentStep(item: any) {
            this.formSummary[this.currentStep] = item;
        },
        setInitSummaryScope(item: string) {
            this.formSummary[FormSteps.Scope] = { scope: item, selectedScopes: [] };
        },
        setFormStepReset(step: number) {
            this.formSummary = this.formSummary.slice(0, step);
        },
        setSummaryScopes(scopes: Receiver[]) {
            this.formSummary[FormSteps.Scope]['selectedScopes'] = scopes;
        },
        toNextStep() {
            this.currentStep = this.currentStep + 1;
        },
        toPrevStep() {
            this.currentStep = this.currentStep - 1;
        },
        handleFormStepChange(step: number) {
            // Navigating between main form steps
            // Instep navigation is done in MessageForm.vue
            const summaryData = this.formSummary; // contains all choices user has made in form

            const scopes = summaryData[FormSteps.Reason]?.messageScopes;
            if (step === FormSteps.Scope && scopes && scopes.length === 1) {
                // Skip scope choosing in case with only option
                this.instepContent = { subtitle: scopes[0], contentType: scopes[0] };
                if (!summaryData[FormSteps.Scope]) {
                    this.setInitSummaryScope(scopes[0]);
                }
            }

            this.currentStep = step;
        },
        selectFormStep(item: any) {
            this.selectedFormItem = item;
            if (this.currentStep === FormSteps.Scope) {
                this.setFormSummaryByCurrentStep({ scope: item, selectedScopes: [] });
            } else {
                this.setFormSummaryByCurrentStep(item);
            }
        }
    }
});
