export interface BaseResource {
    links: Array<Link>;
}

export interface Resource<T> extends BaseResource {
    data: T | null;
}

export interface ListResource<T> extends BaseResource {
    data: T[];
    paging?: Paging;
}

export interface Paging {
    pageNumber: number;
    pageSize: number;
    totalItemCount: number;
    pageCount: number;
}

export const DefaultPageSize = 15;

export const DefaultPerPageOptions = [
    { value: 15, title: '15' },
    { value: 30, title: '30' },
    { value: 100, title: '100' }
];

export const DefaultPaging = {
    pageNumber: 1,
    pageSize: DefaultPageSize,
    totalItemCount: 0
} as Paging;

export interface Link {
    rel: string;
    href: string;
    method: string;
}

export interface Settings {
    googleMapsApiKey: string;
}

export interface AutocompleteItem<T> {
    title: string;
    value: string;
    data: T;
}

export enum TransportType {
    None = 0,
    Bus = 1,
    Water = 2,
    Rail = 3,
    Bike = 4,
    Unknown = 5
}

export interface CookieSettings {
    sourceSystem: string;
}

export const CookieConstants = {
    settingsName: 'settings'
};
