<template>
    <div class="summary-container">
        <div class="summary-header">
            <h3 class="summary-title">{{ title }}</h3>
            <v-btn class="text-none font-weight-bold" variant="outlined" color="primary" @click="emit('back')">{{
                t('messages.resources.formSummaryBackButtonText', 'Tilbake til oversikt')
            }}</v-btn>
        </div>
        <div class="summary-content">
            <slot></slot>
        </div>
        <div v-if="isSubmitVisible" class="summary-footer">
            <v-btn
                class="text-none font-weight-bold submit-button"
                color="primary"
                :disabled="submitButtonDisabled"
                @click="emit('submit')"
                >{{ submitButtonText }}</v-btn
            >
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface Props {
    title: string;
    isSubmitVisible?: boolean;
    submitButtonText: string;
    submitButtonDisabled?: boolean;
}

const emit = defineEmits<{
    (e: 'back'): void;
    (e: 'submit'): void;
}>();

withDefaults(defineProps<Props>(), {
    isSubmitVisible: false,
    submitButtonDisabled: true
});
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.summary-container {
    background-color: $lightGrey;
    padding: 24px 16px 32px 16px;
    display: flex;
    flex-direction: column;

    .summary-header {
        display: flex;
        justify-content: space-between;
    }

    .summary-title {
        font-size: 20px;
    }

    .summary-content {
        padding: 16px 0 32px 0;
    }

    .summary-footer {
        display: flex;
        justify-content: right;
    }
}

.submit-button.v-btn.v-btn--disabled.v-btn--variant-elevated {
    background-color: $disabledSubmitGrey !important;
}
</style>
