import router from '@/router';

export default function useGlobalErrorHandler() {
    return function (err: any, vm: any, info: any) {
        const linkNotFound = err.toString().indexOf('No link found');
        if (linkNotFound > -1) {
            /* eslint-disable no-console */
            console.warn(err.message);
            /* eslint-enable no-console */
            router.push({ path: '/accessdenied' });
            return;
        }

        console.error('Error:', err);
        console.error('Vue component:', vm);
        console.error('Additional info:', info);
    };
}
