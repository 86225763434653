import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import 'leaflet/dist/leaflet.css';
import '@/styles/main.scss';
import useGlobalErrorHandler from '@/shared/UseGlobalErrorHandler';

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(createPinia());

app.config.errorHandler = useGlobalErrorHandler();

app.mount('#app');
