<template>
    <div class="message-form">
        <div class="form-header">{{ t(`messages.resources.editMessage`) }}</div>
        <div class="form-content">
            <form-navigation-bar
                :current="messagesStore.currentStep"
                :steps="steps"
                class="nav-bar"
                @form-step-changed="navigateFromHeader"
            />
            <div class="step-content">
                <div class="step-title">
                    {{ t(`messages.resources.${steps[messagesStore.currentStep]}`) }}
                </div>
                <edit-form-content :has-editor="editor" :is-old-message="isOldMessage" />
            </div>
            <div class="form-controls">
                <div>
                    <v-btn color="primary" data-id="CancelButton" variant="outlined" @click="cancel()">
                        {{ t('shared.resources.common.cancel') }}
                    </v-btn>
                    <v-btn color="error" data-id="UnpublishButton" @click="confirmUnpublish">
                        {{ t('messages.resources.unpublish') }}
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        v-if="messagesStore.currentStep > 0"
                        color="primary"
                        data-id="BackButton"
                        variant="outlined"
                        @click="messagesStore.toPrevStep()"
                    >
                        {{ t('shared.resources.common.back') }}
                    </v-btn>
                    <v-btn
                        v-if="messagesStore.currentStep < 1 && steps.length > 1"
                        class="white--text"
                        color="primary"
                        data-id="NextButton"
                        @click="messagesStore.toNextStep()"
                    >
                        {{ t('shared.resources.common.next') }}
                    </v-btn>
                    <v-btn v-if="canSaveMessage" class="white--text" color="primary" data-id="SavePublishButton" @click="saveChanges">
                        {{ t('messages.resources.existingMessageSaveAndRepublish') }}
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="form-summary">
            <form-summary />
        </div>
    </div>
</template>

<script lang="ts" setup>
import FormNavigationBar from './FormNavigationBar.vue';
import FormSummary from './FormSummary.vue';
import EditFormContent from './EditFormContent.vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { FormSteps, Scopes } from './messagesModels';
import { useMessagesStore } from './messagesStore';

const messagesStore = useMessagesStore();
const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const editor = ref(false);
const isOldMessage = ref(false);
const steps = computed(() => (editor.value ? ['freeText', 'publishing'] : ['publishing']));
const canSaveMessage = computed(
    () => messagesStore.formSummary[FormSteps.Scope].scope !== Scopes.Departures || (editor.value && messagesStore.currentStep > 0)
);

messagesStore.currentStep = 0;
await messagesStore.loadMessage(route.params.id as string);

if (messagesStore.messageTemplates.length < 1) {
    await messagesStore.loadMessageTemplates();
}
setupLoadedMessage();

function isEmptyObject(obj: any) {
    return JSON.stringify(obj) === '{}';
}

function setupLoadedMessage() {
    const templateId = messagesStore.message.structuredMessage.templateId;
    const template = messagesStore.getMessageTemplateById(templateId);
    if (template) {
        setupTemplateMessage(template);
    } else {
        setupOldMessage();
    }
}

function setupTemplateMessage(template: any) {
    editor.value = template.editable;
    const input = messagesStore.message.structuredMessage.input;
    const choice = messagesStore.message.structuredMessage.choice;
    const summary = [
        {
            label: template.categoryLabel,
            name: template.categoryName
        },
        {
            label: template.label,
            name: template.name,
            input: isEmptyObject(input) ? null : input,
            inputTemplates: isEmptyObject(input) ? null : template.inputTemplates,
            choice: isEmptyObject(choice) ? null : choice,
            choiceTemplates: isEmptyObject(choice) ? null : template.choiceTemplates,
            message: messagesStore.message.body,
            pushAvailable: template.pushAvailable,
            pushNotifications: messagesStore.message.pushNotifications
        },
        findSelectedScopes(),
        {
            startPublish: messagesStore.message.startPublish,
            endPublish: messagesStore.message.endPublish
        }
    ];
    messagesStore.formSummary = summary;
}

function setupOldMessage() {
    isOldMessage.value = true;

    const summary = [
        {
            label: messagesStore.message.subject,
            name: messagesStore.message.subjectEn
        },
        {
            label: '',
            name: ''
        },
        findSelectedScopes()
    ];
    messagesStore.formSummary = summary;
}

function findSelectedScopes() {
    const receivers = messagesStore.message.receivers;
    let scopeName = '';
    let selectedScopes = [] as any;

    for (const scope in receivers) {
        if (receivers[scope].length && scope === 'scopes') {
            scopeName = receivers[scope][0];
        } else if (receivers[scope].length) {
            if (!scopeName.length) scopeName = getScopeName(scope); // fallback for older entries
            selectedScopes = receivers[scope];
        }
    }
    return { scope: scopeName, selectedScopes: selectedScopes };
}

function getScopeName(scope: string) {
    return scope.charAt(0).toUpperCase() + scope.substring(1);
}

function navigateFromHeader(step: number) {
    if (messagesStore.currentStep !== step) {
        messagesStore.currentStep = step;
    }
}

async function saveChanges() {
    messagesStore.message.body = messagesStore.formSummary[FormSteps.Reason].message;
    messagesStore.message.startPublish = messagesStore.formSummary[FormSteps.Publishing].startPublish;
    messagesStore.message.endPublish = messagesStore.formSummary[FormSteps.Publishing].endPublish;

    await messagesStore.updateMessage();
    router.go(-1);
}

async function confirmUnpublish() {
    const message = t('messages.resources.unpublishNotificationMessage');
    if (confirm(message)) {
        await messagesStore.unpublishMessage();
        router.go(-1);
    }
}

function cancel() {
    messagesStore.resetForm(0);
    router.go(-1);
}
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
    padding: 12px;
}

.message-form {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 90px 1fr;
    grid-template-areas:
        'header header'
        'content summary';
    height: 100%;
    margin: 24px;

    .form-header {
        grid-area: header;
        background-color: #f3f3f3;
        padding: 0 50px;
        font-size: 30px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    .form-content {
        grid-area: content;
        padding: 10px 50px 10px 20px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50px 1fr 40px;

        .nav-bar {
            grid-row: 1 / 2;
            padding-left: 20px;
        }

        .step-title {
            padding-top: 50px;
            padding-bottom: 10px;
            font-weight: 700;
            font-size: 25px;
            line-height: 33px;
        }

        .step-content {
            grid-row: 2 / 3;
            min-height: 450px;
        }

        .form-controls {
            grid-row: 3 / 4;
            display: flex;
            justify-content: space-between;
        }
    }

    .form-summary {
        grid-area: summary;
        background-color: #dedede;
    }
}
</style>
