<template>
    <div class="form-navigation-bar">
        <div
            v-for="(step, i) in props.steps"
            :key="i"
            :class="{ active: i === props.current, previous: i < (props.current ?? 0) }"
            :data-id="`Navigation${getStepId(step)}`"
            class="bar-item"
            @click="setFormStep(i)"
        >
            <span>{{ t(`messages.resources.${step}`) }}</span>
            <i class="arrow right"></i>
            <i class="arrow reverse"></i>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const { t } = useI18n();

const props = defineProps<{
    steps: string[];
    current: number;
}>();
const emit = defineEmits(['formStepChanged']);

const editMode = ref(false);

if (route.params.id) editMode.value = true;

function setFormStep(step: number) {
    if (!editMode.value && step >= (props.current ?? 0)) return;
    emit('formStepChanged', step);
}

function getStepId(step: any) {
    return step.charAt(0).toUpperCase() + step.substring(1);
}
</script>

<style lang="scss" scoped>
.form-navigation-bar {
    display: flex;
    flex-direction: row;
    width: calc(100% + 30px);
    font-size: 15px;
    align-items: center;
    font-weight: 400;

    .bar-item {
        width: 25%;
        height: 30px;
        background-color: #e4f4e4;
        position: relative;
        margin-right: 30px;
        margin-left: -20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            color: #ffffff;
            background-color: #3ec652;

            .arrow.right {
                background-color: #3ec652;
            }
        }

        &.previous {
            background-color: #b8ecb3;
            cursor: pointer;

            .arrow.right {
                background-color: #b8ecb3;
            }
        }

        &:last-child {
            .arrow.right {
                display: none;
            }
        }

        &:first-child {
            .arrow.reverse {
                display: none;
            }
        }

        .arrow {
            height: 20px;
            width: 20px;
            top: 5px;
            display: block;
            position: absolute;
            transform: rotate(-45deg);

            &.right {
                background-color: #e4f4e4;
                right: -10px;
                z-index: 1;
            }

            &.reverse {
                background-color: #ffffff;
                left: -10px;
            }
        }
    }
}
</style>
