<template>
    <div class="driversHandbooks">
        <driver-handbooks-form :drivers-handbook="driversHandbooksStore.driversHandbook"></driver-handbooks-form>
    </div>
</template>

<script lang="ts" setup>
import DriverHandbooksForm from './DriverHandbooksForm.vue';
import { useDriversHandbooksStore } from './driversHandbooksStore';

const driversHandbooksStore = useDriversHandbooksStore();

await driversHandbooksStore.initNewDriversHandbook();
</script>
