<template>
    <div>
        <subscription-form :subscription="subscriptionsStore.subscription"></subscription-form>
    </div>
</template>

<script lang="ts" setup>
import SubscriptionForm from './SubscriptionForm.vue';
import { useRoute } from 'vue-router';
import { useSubscriptionsStore } from './subscriptionsStore';

const subscriptionsStore = useSubscriptionsStore();
const route = useRoute();

await subscriptionsStore.loadSubscription(Number(route.params.id));
</script>
