import { defineStore } from 'pinia';
import { MpcStatisticsLine } from './mpcStatisticsModels';
import { format } from 'date-fns-tz';
import { Link } from '@/shared/models';
import { MpcStatisticsRels } from './rels';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';

export const useMpcStatisticsStore = defineStore('mpcStatistics', {
    state: () => ({
        mpcStatistics: new Array<MpcStatisticsLine>(),
        currentDate: format(new Date(), 'yyyy-MM-dd'),
        links: new Array<Link>()
    }),
    actions: {
        async loadMpcStatistics() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MpcStatisticsRels.getMpcStatistics);

            const currentDate = this.currentDate;
            const requestParams = new URLSearchParams();
            requestParams.append('utcWhen', currentDate);

            const response = await axios.requestByLink(link, { params: requestParams });

            this.mpcStatistics = response.data.data;
            this.links = response.data.links;
        }
    }
});
