<template>
    <v-main>
        <v-form ref="form" v-model="valid" lazy-validation>
            <div class="subscription">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-row>
                                <v-col>
                                    <label> {{ t('subscriptions.resources.messageSubject') }}: </label>
                                    <v-text-field
                                        v-model="subscription.messageSubject"
                                        :rules="[rules.required]"
                                        data-id="SubjectTextField"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <label> {{ t('subscriptions.resources.companies') }}: </label>
                                    <v-autocomplete
                                        v-model="selectedCompanies"
                                        :items="autocompleteCompanies"
                                        :placeholder="t('subscriptions.resources.companies')"
                                        :rules="[selectCompany]"
                                        append-inner-icon="search"
                                        return-object
                                        multiple
                                        chips
                                        closable-chips
                                        clearable
                                        data-id="NameAutoComplete"
                                    >
                                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                                            <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.value}`"></v-list-item>
                                        </template>
                                    </v-autocomplete>
                                    <label> {{ t('subscriptions.resources.lines') }}: </label>
                                    <v-autocomplete
                                        v-model="selectedLines"
                                        :items="autocompleteLines"
                                        :placeholder="t('subscriptions.resources.lines')"
                                        :rules="[selectLine]"
                                        append-inner-icon="search"
                                        return-object
                                        multiple
                                        chips
                                        closable-chips
                                        clearable
                                        data-id="NameAutoComplete"
                                    >
                                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                                            <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.value}`"></v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-row v-if="subscription.companies.length > 0" class="selectedGroup">
                                        <v-label>
                                            {{ t('subscriptions.resources.companies') }}
                                        </v-label>
                                        <div
                                            v-for="(company, i) in subscription.companies"
                                            :key="i"
                                            class="selectedBtn"
                                            data-id="RemoveDiv"
                                            @click="removeSelectedItem(i, subscription.companies)"
                                        >
                                            {{ getCompanyName(company) }}
                                            <v-icon icon="mdi-close" size="small"></v-icon>
                                        </div>
                                    </v-row>
                                    <v-row v-if="subscription.lines.length > 0" class="selectedGroup">
                                        <v-label>
                                            {{ t('subscriptions.resources.lines') }}
                                        </v-label>
                                        <div
                                            v-for="(line, i) in subscription.lines"
                                            :key="i"
                                            class="selectedBtn"
                                            data-id="RemoveDiv"
                                            @click="removeSelectedItem(i, subscription.lines)"
                                        >
                                            {{ getLineName(line) }}
                                            <v-icon icon="mdi-close" size="small"></v-icon>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <subscription-subscribers-table ref="subscribersForm" :subscribers="subscription.subscribers" />

                            <v-btn
                                v-if="existingSubscription"
                                class="deleteBtn"
                                color="error"
                                data-id="DeleteButton"
                                @click="removeSubscription"
                            >
                                {{ t('shared.resources.common.delete') }}
                            </v-btn>

                            <v-btn
                                :disabled="!valid"
                                class="white--text"
                                color="primary"
                                data-id="SaveButton"
                                @click="existingSubscription ? saveSubscription() : createNewSubscription()"
                            >
                                {{ t('shared.resources.common.save') }}
                            </v-btn>

                            <v-btn color="primary" data-id="BackButton" variant="outlined" @click="$router.go(-1)">
                                {{ t('shared.resources.common.back') }}
                            </v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </v-main>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import SubscriptionSubscribersTable from './SubscriptionSubscribersTable.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { AutocompleteItem } from '@/shared/models';
import { Line } from '../Lines/linesModels';
import { useLinesStore } from '../Lines/linesStore';
import { toLineAutocompleteItem } from '../Lines/lineHelpers';
import { useCompaniesStore } from '../Companies/companiesStore';
import { toCompanyAutocompleteItem } from '../Companies/companyHelpers';
import { Company } from '../Companies/companiesModels';
import { useSubscriptionsStore } from './subscriptionsStore';
import { Subscriber, Subscription } from './subscriptionsModels';

interface ValidatableSubscriber extends Subscriber {
    emailRules: any[];
    phoneRules: any[];
}

const subscriptionsStore = useSubscriptionsStore();
const companiesStore = useCompaniesStore();
const linesStore = useLinesStore();
const { t } = useI18n();
const router = useRouter();

const props = defineProps<{ subscription: Subscription }>();
const valid = ref(true);
const selectedCompanies = ref<AutocompleteItem<Company>[]>([]);
const selectedLines = ref<AutocompleteItem<Line>[]>([]);
const existingSubscription = ref(false);

const rules = {
    required: (v: any) => !!v || t('shared.resources.common.requiredProperty'),
    email: (v: any) => /.+@.+\..+/.test(v) || t('shared.resources.common.invalidEmail'),
    phone: (v: any) => /^\+?\d+$/.test(v) || t('shared.resources.common.invalidPhone'),
    selectOneText: (v: any) => v.length > 0 || t('shared.resources.common.selectOption'),
    selectOne: (v: any) => v.length > 0 || false
};

const subscribers = ref<ValidatableSubscriber[]>([
    {
        fullName: '',
        phone: '',
        email: '',
        messageTypes: [],
        subscriptionTypes: [],
        emailRules: [],
        phoneRules: []
    }
]);

const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: {
                id: string | number;
                errorMessages: string[];
            }[];
        }>;
    }
>();

const subscribersForm = ref<null | InstanceType<typeof SubscriptionSubscribersTable>>(null);

const autocompleteLines = computed<AutocompleteItem<Line>[]>(() => linesStore.linesWithAll.map(x => toLineAutocompleteItem(x)));
const autocompleteCompanies = computed<AutocompleteItem<Company>[]>(() =>
    companiesStore.companiesWithAll.map(x => toCompanyAutocompleteItem(x))
);
const subscription = computed(() => props.subscription);

await linesStore.loadLines();
await companiesStore.loadCompanies();

subscription.value.subscribers.forEach((user: any) => {
    user.emailRules = [];
    user.phoneRules = [];
    subscribers.value.push(user);
});
existingSubscription.value = Object.hasOwn(subscription.value, 'id');

selectedCompanies.value = autocompleteCompanies.value.filter(x => subscription.value.companies.some(y => y === x.data.companyNumber));
selectedLines.value = autocompleteLines.value.filter(x => subscription.value.lines.some(y => y === x.data.privateCode));

watch(selectedCompanies, async val => {
    if (val.length < 1) {
        subscription.value.companies = [];
    }
    const last = val[val.length - 1];
    const lastSelected = last?.data;

    if (lastSelected.companyNumber === '0') {
        if (val.length > 1) {
            subscription.value.companies = [lastSelected.companyNumber];
            selectedCompanies.value = [last];
        }
        return;
    }

    if (!hasSelectedCompany(lastSelected.companyNumber)) {
        subscription.value.companies.push(lastSelected.companyNumber);
    }

    if (selectedCompanies.value.some(x => x.value === '0')) {
        subscription.value.companies = subscription.value.companies.filter(x => x !== '0');
        selectedCompanies.value = selectedCompanies.value.filter(x => x.value !== '0');
    }
});

watch(selectedLines, async val => {
    if (val.length < 1) {
        subscription.value.lines = [];
    }

    const last = val[val.length - 1];
    const lastSelected = last?.data;

    if (lastSelected.privateCode === '0') {
        if (val.length > 1) {
            subscription.value.lines = [lastSelected.privateCode];
            selectedLines.value = [last];
        }
        return;
    }

    if (!hasSelectedLine(lastSelected.privateCode)) {
        subscription.value.lines.push(lastSelected.privateCode);
    }

    if (selectedLines.value.some(x => x.value === '0')) {
        subscription.value.lines = subscription.value.lines.filter(x => x !== '0');
        selectedLines.value = selectedLines.value.filter(x => x.value !== '0');
    }
});

function hasSelectedCompany(companyNumber: string) {
    return subscription.value.companies.some((el: string) => el === companyNumber);
}

function hasSelectedLine(privateCode: string) {
    return subscription.value.lines.some((el: string) => el === privateCode);
}

function selectCompany() {
    if (subscription.value.companies.length < 1) {
        return t('subscriptions.resources.selectCompanyRule');
    } else {
        return true;
    }
}

function selectLine() {
    if (subscription.value.lines.length < 1) {
        return t('subscriptions.resources.selectLineRule');
    } else {
        return true;
    }
}

function getCompanyName(companyNumber: string) {
    if (companyNumber === '0') {
        return t('shared.resources.common.all');
    }
    const company = autocompleteCompanies.value.find(x => x.data.companyNumber === companyNumber);
    return company?.title;
}

function getLineName(privateCode: string) {
    if (privateCode === '0') {
        return t('shared.resources.common.all');
    }
    const line = autocompleteLines.value.find(x => x.data.privateCode === privateCode);
    return line?.title;
}

function removeSelectedItem(index: number, array: Array<any>) {
    array.splice(index, 1);
}

function updateSubscriptionModel() {
    const subs = subscribers.value.filter(user => user.messageTypes.length > 0);
    subs.forEach(user => {
        user.phoneRules = [];
        user.emailRules = [];
    });
    subscription.value.subscribers = subs;
}

async function createNewSubscription() {
    updateSubscriptionModel();
    const isValid = await validate();
    if (isValid) {
        await subscriptionsStore.createSubscription();
        router.go(-1);
    }
}

async function saveSubscription() {
    updateSubscriptionModel();

    const isValid = await validate();
    if (isValid) {
        await subscriptionsStore.updateSubscription();
        router.go(-1);
    }
}

async function removeSubscription() {
    const message = t('shared.resources.common.deleteNotificationMessage');
    if (confirm(message)) {
        await subscriptionsStore.deleteSubscription();
        router.go(-1);
    }
}

async function validate() {
    const isFormValid = await form.value?.validate();
    const isSubscribersFormValid = await subscribersForm.value?.validate();
    return isFormValid && isSubscribersFormValid;
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.v-btn {
    float: right;
    margin-left: 12px;
    margin-top: 32px;

    &.deleteBtn {
        float: left;
        margin-left: 0;
    }
}

.selectedBtn {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 2px;
    max-width: 100%;
    text-align: center;
    min-height: 36px;
    padding: 2px 24px;
    background: $primary;
    border-color: $primary;
    color: white;
    text-transform: uppercase;
    border-radius: 28px;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    i.v-icon {
        color: white;
        position: absolute;
        width: 12px;
        right: 8px;
        top: 10px;
        bottom: 0;

        font-size: 16px;
    }

    &[disabled] {
        background: $disabledGrey;
        box-shadow: none;
    }
}
</style>
