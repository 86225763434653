<template>
    <div class="drivers-summary">
        <div v-for="driver in drivers" :key="driver.id" class="driver">
            <div class="drivers-title">{{ t('messages.resources.companies', 'Operatør') }}</div>
            <div class="selected-drivers">{{ driver.companyId }}</div>
            <div class="drivers-title">{{ t('messages.resources.driverId', 'Sjåfør ID') }}</div>
            <div class="selected-drivers">{{ driver.name }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DriverReceiver } from '../../messagesModels';
import { useI18n } from 'vue-i18n';

interface Props {
    drivers: Array<DriverReceiver>;
}
defineProps<Props>();
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.drivers-title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}

.selected-drivers {
    font-size: 14px;
}
</style>
