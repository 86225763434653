<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <div>
            <v-row>
                <v-col>
                    <v-card>
                        <label> {{ t('driversHandbooks.resources.name') }}: </label>
                        <v-text-field v-model="formDriversHandbook.name" :rules="formRules" data-id="NameTextfield" required></v-text-field>
                        <label> {{ t('driversHandbooks.resources.sortOrder') }}: </label>
                        <v-text-field
                            v-model="formDriversHandbook.sortOrder"
                            :rules="wholeNumberRule"
                            data-id="SortOrderTextfield"
                            required
                            type="number"
                        ></v-text-field>
                        <label> {{ t('driversHandbooks.resources.heading') }}: </label>
                        <v-text-field v-model="formDriversHandbook.heading" data-id="HeadingTextfield" required></v-text-field>
                        <label class="content-label"> {{ t('driversHandbooks.resources.content') }}: </label>
                        <editor
                            v-model="formDriversHandbook.content"
                            :init="{
                                entity_encoding: 'raw',
                                height: 500,
                                menubar: false,
                                language: 'nb_NO',
                                language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/nb_NO.js',
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                   alignleft aligncenter alignright alignjustify | \
                                   bullist numlist outdent indent | removeformat | link | help'
                            }"
                            data-id="ContentEditor"
                            required
                        />
                        <v-btn
                            :disabled="!valid"
                            class="white--text"
                            color="primary"
                            data-id="SaveButton"
                            @click="existingDriversHandbook ? saveDriversHandbook() : createDriverHandbook()"
                        >
                            {{ t('shared.resources.common.save') }}
                        </v-btn>
                        <v-btn
                            v-if="existingDriversHandbook"
                            class="deleteBtn"
                            color="error"
                            data-id="DeleteButton"
                            @click="removeDriversHandbook()"
                        >
                            {{ t('shared.resources.common.delete') }}
                        </v-btn>
                        <v-btn color="primary" data-id="BackButton" variant="outlined" @click="$router.go(-1)">
                            {{ t('shared.resources.common.back') }}
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script lang="ts" setup>
import Editor from '@tinymce/tinymce-vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { DriverHandbook } from './driversHandbooksModels';
import { useDriversHandbooksStore } from './driversHandbooksStore';

const driversHandbooksStore = useDriversHandbooksStore();
const { t } = useI18n();
const router = useRouter();

const props = defineProps<{
    driversHandbook: DriverHandbook;
}>();
const valid = ref(true);
const existingDriversHandbook = ref(false);
const formRules = [(v: any) => !!v || t('shared.resources.common.requiredProperty')];
const wholeNumberRule = [(v: number) => (!!v && v % 1 === 0 && v >= 1 && v <= 999) || t('shared.resources.common.wholeNumberValidation')];
const formDriversHandbook = computed(() => props.driversHandbook);
const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: {
                id: string | number;
                errorMessages: string[];
            }[];
        }>;
    }
>();

existingDriversHandbook.value = Object.hasOwn(props.driversHandbook, 'id');

async function createDriverHandbook() {
    const isValid = await form.value?.validate();
    if (isValid) {
        await driversHandbooksStore.createDriversHandbook();
        router.go(-1);
    }
}

async function saveDriversHandbook() {
    const isValid = await form.value?.validate();
    if (isValid) {
        await driversHandbooksStore.updateDriversHandbook();
        router.go(-1);
    }
}

async function removeDriversHandbook() {
    const message = t('shared.resources.common.deleteNotificationMessage');
    if (confirm(message)) {
        await driversHandbooksStore.deleteDriversHandbook();
        router.go(-1);
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    padding: 30px 40px;
    overflow: hidden;
}

.v-btn {
    float: right;
    margin-left: 12px;
    margin-top: 16px;

    &.deleteBtn {
        float: left;
        margin-left: 0;
    }
}

.content-label {
    display: block;
    padding-bottom: 16px;
}

.driversHandbooks {
    margin-top: 12px;
}
</style>
