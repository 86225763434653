<template>
    <div class="accessdenied">
        <v-main>
            <v-row>
                <v-col class="warn">
                    <div>
                        {{ t('shared.resources.common.accessDenied') }}
                    </div>
                    <div>
                        <v-icon size="large" icon="mdi-alert"></v-icon>
                    </div>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.warn {
    font-size: 36px;
    text-align: center;
}
</style>
