<template>
    <div class="subscriptions">
        <v-main>
            <v-row>
                <v-col>
                    <v-row class="controls">
                        <v-btn class="white--text" color="primary" data-id="AddNewButton" @click="createSubscription">
                            {{ t('shared.resources.common.addNew') }}
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="subscriptionsStore.subscriptions"
                        :items-per-page="DefaultPageSize"
                        :loading="loadingTable"
                        :page="page"
                        data-id="DataTable"
                        disable-sort
                    >
                        <template #[`item.companies`]="{ item }">
                            <span>
                                {{ getCompanyNames(item.companies) }}
                            </span>
                        </template>
                        <template #[`item.lines`]="{ item }">
                            <span>
                                {{ getLineNames(item.lines) }}
                            </span>
                        </template>
                        <template #[`item.subscribers`]="{ item }">
                            <span v-for="(subscriber, index) in item.subscribers" :key="subscriber.id"
                                >{{ subscriber.fullName }}<template v-if="index + 1 < item.subscribers.length">, </template>
                            </span>
                        </template>
                        <template #[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="{ props }">
                                    <v-icon
                                        class="mr-2"
                                        data-id="EditIcon"
                                        style="outline: none"
                                        v-bind="props"
                                        icon="mdi-pencil"
                                        @click="editItem(item.id)"
                                    ></v-icon>
                                </template>
                                <span>
                                    {{ t('shared.resources.common.edit') }}
                                </span>
                            </v-tooltip>
                        </template>
                        <template #bottom></template>
                    </v-data-table>
                    <v-row>
                        <v-col cols="8">
                            <div v-if="subscriptionsStore.paging.totalItemCount !== undefined" class="totalSubscriptions">
                                {{ t('subscriptions.resources.subscriptions') + ': ' + subscriptionsStore.paging.totalItemCount }}
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <v-pagination v-model="page" :length="subscriptionsStore.paging.pageCount"></v-pagination>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useLinesStore } from '../Lines/linesStore';
import { useCompaniesStore } from '../Companies/companiesStore';
import { DefaultPageSize } from '@/shared/models';
import { useSubscriptionsStore } from './subscriptionsStore';

const subscriptionsStore = useSubscriptionsStore();
const linesStore = useLinesStore();
const companiesStore = useCompaniesStore();
const { t } = useI18n();
const router = useRouter();

const loadingTable = ref(true);
const page = ref(1);
const headers = computed(() => [
    {
        title: t('subscriptions.resources.messageSubject'),
        key: 'messageSubject',
        sortable: false
    },
    {
        title: t('subscriptions.resources.companies'),
        key: 'companies',
        sortable: false
    },
    {
        title: t('subscriptions.resources.lines'),
        key: 'lines',
        sortable: false
    },
    {
        title: t('subscriptions.resources.subscribers'),
        key: 'subscribers',
        sortable: false
    },
    {
        title: t('shared.resources.common.edit'),
        key: 'action',
        sortable: false
    }
]);

const lines = computed(() => linesStore.linesWithAll);
const companies = computed(() => companiesStore.companiesWithAll);

await subscriptionsStore.loadSubscriptions(page.value);
loadingTable.value = false;

await linesStore.loadLines();
await companiesStore.loadCompanies();

watch(page, async () => {
    loadingTable.value = true;
    await subscriptionsStore.loadSubscriptions(page.value);
    loadingTable.value = false;
});

function getCompanyNames(subsCompanies: Array<string>) {
    const selectedCompanyNames = [] as Array<string>;
    subsCompanies.forEach((companyNumber: string) => {
        const companyName = companies.value.filter(company => company.companyNumber === companyNumber);
        let name: string;
        if (companyNumber === '0') {
            name = t('shared.resources.common.all');
        } else {
            name = companyName.length ? `${companyName[0].name} (${companyNumber})` : companyNumber;
        }
        selectedCompanyNames.push(name);
    });
    return selectedCompanyNames.join(', ');
}

function getLineNames(subsLines: Array<string>) {
    const selectedLineNames = [] as Array<string>;
    subsLines.forEach((privateCode: string) => {
        const lineName = lines.value.filter(line => line.privateCode === privateCode);
        let name: string;
        if (privateCode === '0') {
            name = t('shared.resources.common.all');
        } else {
            name = lineName.length ? lineName[0].name : privateCode;
        }
        selectedLineNames.push(name);
    });
    return selectedLineNames.join(', ');
}

function editItem(item: number) {
    router.push({ path: `/subscriptions/${item}` });
}

function createSubscription() {
    router.push({ path: `/subscriptions/create` });
}
</script>

<style lang="scss" scoped>
@import '../../shared/variables.scss';

.totalSubscriptions {
    color: $grey;
}

.controls {
    justify-content: flex-end;
    margin: 12px 0;
}
</style>
