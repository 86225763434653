<template>
    <div class="operator-scope">
        <div>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="selectDriver"
                        :label="t('messages.resources.driverId')"
                        data-id="NameAutoComplete"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="modelCompanies"
                        :items="autocompleteCompanies"
                        :placeholder="t('messages.resources.searchCompany')"
                        append-inner-icon="search"
                        return-object
                        data-id="NameAutoComplete"
                    >
                        <template #item="{ item, props: itemProps }: { item: any, props: any }">
                            <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.data.companyNumber}`"></v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col>
                    <v-btn
                        :disabled="!selectDriver || !companySelected"
                        class="driverBtn"
                        color="primary"
                        data-id="AddButton"
                        @click="addDriver()"
                        >Add driver
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div class="selected-drivers">
            <div v-if="selectedDriver" class="driver-btn" data-id="DeleteDiv" @click="removeDriver()">
                {{ `${selectedDriver.id} (${selectedDriver.companyId})` }}
                <v-icon icon="mdi-close-circle-outline" size="small"></v-icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { DriverReceiver, FormSteps, Receiver } from '@/Features/Messages/messagesModels';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { AutocompleteItem } from '@/shared/models';
import { useCompaniesStore } from '@/Features/Companies/companiesStore';
import { Company } from '@/Features/Companies/companiesModels';
import { toCompanyAutocompleteItem } from '@/Features/Companies/companyHelpers';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();
const companiesStore = useCompaniesStore();
const { t } = useI18n();

const selectDriver = ref('');
const selectedDriver = ref<DriverReceiver | null>(null);
const modelCompanies = ref<AutocompleteItem<any> | undefined>();
const selectDriverCompany = ref<Company>({} as Company);
const companySelected = ref(false);
const autocompleteCompanies = computed<AutocompleteItem<Company>[]>(() => companiesStore.companies.map(x => toCompanyAutocompleteItem(x)));

messagesStore.selectedFormItem = [];
if (autocompleteCompanies.value.length === 0) {
    await companiesStore.loadCompanies();
}
const scopes = messagesStore.formSummary[FormSteps.Scope]?.selectedScopes;
if (scopes?.length) {
    selectedDriver.value = scopes[0];
}

watch(modelCompanies, val => {
    if (!val) return;
    selectDriverCompany.value = val.data;
    companySelected.value = true;
});

watch(selectedDriver, () => {
    const driver = (selectedDriver.value ?? {}) as Receiver;
    messagesStore.setSummaryScopes([driver]);
});

function addDriver() {
    selectedDriver.value = {
        name: selectDriver.value,
        id: selectDriver.value,
        companyId: selectDriverCompany.value.companyNumber.toString()
    };
    selectDriver.value = '';
    modelCompanies.value = undefined;
}

function removeDriver() {
    selectedDriver.value = null;
}
</script>

<style scoped lang="scss">
.operator-scope {
    display: grid;
    grid-template-columns: 70% 30%;
}

.selected-drivers {
    border-left: thin rgba(0, 0, 0, 0.2) solid;
    margin-left: 24px;
    padding: 10px 16px 0;

    .driver-btn {
        display: inline-block;
        position: relative;
        font-weight: bold;
        font-size: 18px;
        margin: 2px;
        padding: 2px 4px;
        text-align: center;
        background: transparent;
        color: #000000;
        cursor: pointer;
    }
}
</style>
