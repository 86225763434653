<template>
    <div class="driversHandbooks">
        <v-skeleton-loader v-if="loading" :transition-group="'fade-transition'" type="card"></v-skeleton-loader>
        <driver-handbooks-form v-else :drivers-handbook="driversHandbooksStore.driversHandbook"></driver-handbooks-form>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import DriverHandbooksForm from '@/Features/DriversHandbooks/DriverHandbooksForm.vue';
import { useRoute } from 'vue-router';
import { useDriversHandbooksStore } from './driversHandbooksStore';

const driversHandbooksStore = useDriversHandbooksStore();
const route = useRoute();

const loading = ref(true);

await driversHandbooksStore.loadDriversHandbook(Number(route.params.id));
loading.value = false;
</script>
