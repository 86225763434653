<template>
    <div class="form-inner-content">
        <div v-if="props.hasEditor && messagesStore.currentStep === 0" class="form-content">
            <editor
                v-model="messageText"
                :init="{
                    entity_encoding: 'raw',
                    height: 500,
                    menubar: false,
                    language: 'nb_NO',
                    language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/nb_NO.js',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | link | help',
                    toolbar_mode: 'sliding'
                }"
                data-id="BodyEditor"
                @input="updateMessage"
            />
        </div>
        <div v-else-if="props.isOldMessage" class="old-driver-message">
            <div>{{ messagesStore.message.subject }}</div>
            <div>{{ messagesStore.message.body }}</div>
            <br />
            <div>{{ `${t('messages.resources.channel')}: ${messagesStore.message.channels.join(', ')}` }}</div>
            <br />
            <div>{{ getReceivers() }}</div>
            <br />
            <div>{{ `${t('messages.resources.startPublishing')}: ${formatDate(messagesStore.message.startPublish)}` }}</div>
            <div>{{ `${t('messages.resources.endPublishing')}: ${formatDate(messagesStore.message.endPublish)}` }}</div>
        </div>
        <div v-else>
            <form-publishing />
        </div>
    </div>
</template>

<script lang="ts" setup>
import FormPublishing from './FormPublishing.vue';
import Editor from '@tinymce/tinymce-vue';
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { FormSteps } from './messagesModels';
import { useMessagesStore } from './messagesStore';
import { computed } from 'vue';

const messagesStore = useMessagesStore();
const { t } = useI18n();

const props = defineProps<{
    hasEditor: boolean;
    isOldMessage: boolean;
}>();

const messageText = computed<string>({
    get: () => {
        return messagesStore.message.body ?? '';
    },
    set: (val: string) => {
        updateMessage(val);
    }
});

function updateMessage(msg: string) {
    const data = { item: { ...messagesStore.formSummary[FormSteps.Reason], message: msg }, step: FormSteps.Reason };
    messagesStore.setFormSummaryByStep(data);
}

function getReceivers() {
    let text = '';
    messagesStore.message.receivers.departures.forEach((receiver: any) => {
        text = text.length ? `, ${receiver.name}` : receiver.name;
    });
    return `${t('messages.resources.departures')}: ${text}`;
}

function formatDate(date?: string) {
    if (!date) return '';
    return ToLocalDate(date);
}
</script>

<style lang="scss" scoped>
.old-driver-message {
    font-size: 20px;
}
</style>
