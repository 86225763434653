<template>
    <div class="audit-logs">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-title>
                    {{ t('messages.resources.auditLog', 'Logg') }}
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div v-for="entry in auditEntries" :key="entry.id" class="entry">
                        <div class="label">{{ t('messages.resources.logAction', 'Handling') }}</div>
                        <div>{{ entry.operation }}</div>

                        <div class="label">{{ t('messages.resources.logTime', 'Når (tidspunkt)') }}</div>
                        <div>{{ ToLocalDate(entry.createdAt) }}</div>

                        <div class="label">{{ t('messages.resources.logAuthor', 'Av') }}</div>
                        <div>
                            {{ entry.messageAuthor.firstName }} {{ entry.messageAuthor.lastName }} ({{ entry.messageAuthor.username }})
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script setup lang="ts">
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { MessageAudit } from '../messagesModels';

interface Props {
    auditEntries: Array<MessageAudit>;
}
defineProps<Props>();

const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.audit-logs {
    padding-bottom: 8px;

    :deep(.v-expansion-panel__shadow) {
        box-shadow: none;
    }

    :deep(.v-expansion-panel-text__wrapper) {
        padding: 0;
    }

    .v-expansion-panel,
    :deep(.v-expansion-panel-title__overlay) {
        background-color: $lightGrey;
    }

    .v-expansion-panel-title {
        font-size: 16px;
        font-weight: bold;
        padding: 0;
        min-height: 32px !important;
    }

    .entry {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 12px;
        margin-bottom: 8px;
        padding-top: 8px;
        text-align: right;
        border-top: 1px solid $borderGrey;

        &:first-child {
            border-top: none;
        }

        .label {
            font-weight: bold;
            text-align: left;
        }
    }
}
</style>
