<template>
    <div class="form-summary">
        <div class="summary-title">
            {{ t('messages.resources.summary') }}
        </div>
        <div v-if="messagesStore.formSummary.length" class="summary-wrap">
            <!-- Category -->
            <div
                v-if="messagesStore.formSummary[FormSteps.Categories] && messagesStore.formSummary[FormSteps.Categories].label"
                class="section"
            >
                <div class="summary-label">{{ t('messages.resources.category') }}:</div>
                <div>{{ messagesStore.formSummary[FormSteps.Categories].label }}</div>
            </div>
            <!-- Reason -->
            <div v-if="messagesStore.formSummary[FormSteps.Reason] && messagesStore.formSummary[FormSteps.Reason].label" class="section">
                <div class="summary-label">{{ t('messages.resources.reason') }}:</div>
                <div>{{ `${messagesStore.formSummary[FormSteps.Reason].label} ${getSelectedChoicesInputs()}` }}</div>
            </div>
            <!-- Scope -->
            <div v-if="messagesStore.formSummary[FormSteps.Scope] && messagesStore.formSummary[FormSteps.Scope].scope" class="section">
                <div class="summary-label">{{ t('messages.resources.scope') }}:</div>
                <div v-if="messagesStore.formSummary[FormSteps.Scope].scope === 'Global'">
                    <span>{{ t(`messages.resources.${messagesStore.formSummary[FormSteps.Scope].scope.toLowerCase()}`) }}</span>
                </div>
                <div v-else-if="messagesStore.formSummary[FormSteps.Scope].scope !== Scopes.Departures">
                    <span>{{ t(`messages.resources.${messagesStore.formSummary[FormSteps.Scope].scope.toLowerCase()}`) }}: </span>
                    <span v-for="(scope, i) in messagesStore.formSummary[FormSteps.Scope].selectedScopes" :key="i" class="scope-item">
                        {{ getSummaryScopeName(scope) }}<span>,&nbsp;</span>
                    </span>
                </div>
                <div v-else>
                    <span>{{ t(`messages.resources.${messagesStore.formSummary[FormSteps.Scope].scope.toLowerCase()}`) }}: </span>
                    <span>{{ getDeparturesSummary() }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { FormSteps, Scopes } from './messagesModels';
import { useMessagesStore } from './messagesStore';

const messagesStore = useMessagesStore();
const { t } = useI18n();

function getSummaryScopeName(scope: any) {
    if (scope.companyId) {
        return `${scope.name} (${scope.companyId})`;
    }

    if (scope.fullName) {
        return scope.fullName;
    }

    return scope.name;
}

function getDeparturesSummary() {
    const scopes = messagesStore.formSummary[2].selectedScopes;
    let scopeSummary = [] as string[];
    scopes.forEach((scope: any) => {
        const index = scope.name.indexOf(' ', scope.name.indexOf(' ') + 1);
        let line = scope.name.slice(0, index);
        if (!scopeSummary.includes(line)) scopeSummary.push(line);
    });

    return scopeSummary.join(', ');
}

function getSelectedChoicesInputs() {
    const choiceInputs = [] as any[];
    const choice = messagesStore.formSummary[FormSteps.Reason].choice;
    const input = messagesStore.formSummary[FormSteps.Reason].input;
    if (choice) {
        for (const item in choice) {
            const template = messagesStore.formSummary[FormSteps.Reason].choiceTemplates.find((x: any) => {
                return item === x.group && choice[item] === x.name;
            });
            choiceInputs.push(template.label);
        }
    }
    if (input) {
        for (const item in input) {
            const template = messagesStore.formSummary[FormSteps.Reason].inputTemplates.find((x: any) => {
                return item === x.name;
            });
            if (input[item]) choiceInputs.push(`${template.label}: ${input[item]}`);
        }
    }
    if (!choiceInputs.length) return '';
    return `(${choiceInputs.join(', ')})`;
}
</script>

<style lang="scss" scoped>
.form-summary {
    background-color: #dedede;
    padding: 30px;
    font-size: 25px;

    .section {
        margin-bottom: 32px;
    }

    .summary-title {
        font-weight: bold;
        margin-bottom: 25px;
    }

    .summary-wrap {
        background-color: #ffffff;
        padding: 12px 20px;

        .summary-label {
            font-weight: bold;
        }

        .scope-item:last-child span {
            display: none;
        }
    }
}
</style>
