<template>
    <Suspense>
        <div class="form-inner-content">
            <div v-if="messagesStore.currentStep === FormSteps.Publishing">
                <form-publishing />
            </div>
            <div v-else-if="isContentEditor()" class="form-content">
                <editor
                    v-model="messageText"
                    :init="{
                        entity_encoding: 'raw',
                        height: 500,
                        menubar: false,
                        language: 'nb_NO',
                        language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/nb_NO.js',
                        plugins: ['autolink link charmap paste help wordcount'],
                        toolbar: 'bold italic | link | help'
                    }"
                    data-id="BodyEditor"
                />
            </div>
            <div v-else-if="isChoiceOrInput()">
                <choices-input-form />
            </div>
            <div v-else-if="isScope(Scopes.Departures)">
                <departures-scope />
            </div>
            <div v-else-if="isScope(Scopes.Lines)">
                <line-scope />
            </div>
            <div v-else-if="isScope(Scopes.Stops)">
                <stops-scope />
            </div>
            <div v-else-if="isScope(Scopes.Areas)">
                <area-scope />
            </div>
            <div v-else-if="isScope(Scopes.Companies)">
                <operator-scope />
            </div>
            <div v-else-if="isScope(Scopes.Drivers)">
                <driver-scope />
            </div>
            <!-- handle multiple scope choices -->
            <div
                v-else-if="
                    messagesStore.currentStep === FormSteps.Scope && messagesStore.formSummary[FormSteps.Reason].messageScopes.length > 1
                "
                class="scopes"
            >
                <div class="list-items">
                    <div
                        v-for="(item, i) in messagesStore.formSummary[FormSteps.Reason].messageScopes"
                        :key="i"
                        :class="{ selected: isSelectedItem(item) }"
                        :data-id="`Scope${item}`"
                        class="item"
                        @click="messagesStore.selectFormStep(item)"
                    >
                        <span>{{ t(`messages.resources.${item.toLowerCase()}`) }}</span>
                    </div>
                </div>
            </div>
            <div v-else-if="messagesStore.currentStep === FormSteps.Categories" class="list-items">
                <div
                    v-for="(item, i) in messagesStore.categoryTemplates"
                    :key="i"
                    :class="{ selected: isSelectedItem(item) }"
                    :data-id="`Category${item.name}`"
                    class="item"
                    @click="messagesStore.selectFormStep(item)"
                >
                    <span>{{ item.label }}</span>
                </div>
            </div>
            <div v-else class="list-items">
                <div
                    v-for="(item, i) in messagesStore.categoryMessageTemplates"
                    :id="item.name"
                    :key="i"
                    :class="{ selected: isSelectedItem(item) }"
                    :data-id="`Reason${item.name}`"
                    class="item"
                    @click="messagesStore.selectFormStep(item)"
                >
                    <span>{{ item.label }}</span>
                    <span v-if="item.transportTypes && item.transportTypes.length === 1" class="transport-type">{{
                        `(${getTransportName(item.transportTypes[0])})`
                    }}</span>
                </div>
            </div>
        </div>
        <template #fallback>
            <v-container>
                <v-skeleton-loader :transition-group="'fade-transition'" type="paragraph"></v-skeleton-loader>
            </v-container>
        </template>
    </Suspense>
</template>

<script lang="ts" setup>
import DeparturesScope from './Scopes/DeparturesScope.vue';
import LineScope from './Scopes/LineScope.vue';
import StopsScope from './Scopes/StopsScope.vue';
import AreaScope from './Scopes/AreaScope.vue';
import OperatorScope from './Scopes/OperatorScope.vue';
import DriverScope from './Scopes/DriverScope.vue';
import FormPublishing from './FormPublishing.vue';
import ChoicesInputForm from './ChoicesInput/ChoicesInputForm.vue';
import Editor from '@tinymce/tinymce-vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { FormSteps, Scopes, TransportType } from './messagesModels';
import { useMessagesStore } from './messagesStore';

const messagesStore = useMessagesStore();
const { t } = useI18n();

const messageText = computed<string>({
    get: () => {
        return messagesStore.formMessageText;
    },
    set: (val: string) => {
        updateMessage(val);
    }
});

function updateMessage(msg: string) {
    messagesStore.selectedFormItem = { message: msg };
    messagesStore.setFormSummaryByCurrentStep({ ...messagesStore.formSummary[messagesStore.currentStep], message: msg });
}

function isScope(scope: string) {
    if (!messagesStore.instepContent) return false;
    return messagesStore.instepContent.contentType === scope;
}

function isChoiceOrInput() {
    return messagesStore.instepContent?.contentType === 'choiceOrInput';
}

function isContentEditor() {
    return messagesStore.instepContent?.contentType === 'editor';
}

function isSelectedItem(item: any) {
    const currentFormStep = messagesStore.formSummary[messagesStore.currentStep];

    if (item.id) return item.id === currentFormStep?.id;
    return item === currentFormStep?.scope;
}

function getTransportName(transport: TransportType) {
    switch (transport) {
        case TransportType.Bus:
            return t('messages.resources.bus');
        case TransportType.Water:
            return t('messages.resources.boat');
        default:
            return transport;
    }
}
</script>

<style lang="scss" scoped>
.list-items {
    display: flex;
    flex-wrap: wrap;

    .item {
        width: 30%;
        max-width: 250px;
        min-height: 110px;
        background-color: #f3f3f3;
        padding: 10px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
        margin-right: 20px;
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selected {
            background-color: #3ec652;
            color: #ffffff;
        }

        &:hover:not(.selected) {
            cursor: pointer;
            background-color: #ededed;
        }

        .transport-type {
            margin-left: 5px;
        }
    }

    #DifficultWeatherConditions span {
        width: min-content;
    }
}
</style>

<style lang="scss">
// Editor toolbox alignment
.tox .tox-toolbar__primary {
    justify-content: flex-end;
}
</style>
