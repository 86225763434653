<template>
    <div>
        <subscription-form :subscription="subscriptionsStore.subscription"></subscription-form>
    </div>
</template>

<script lang="ts" setup>
import SubscriptionForm from './SubscriptionForm.vue';
import { useSubscriptionsStore } from './subscriptionsStore';

const subscriptionsStore = useSubscriptionsStore();

subscriptionsStore.initSubscription();
</script>
