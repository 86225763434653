<template>
    <div>
        <access-tokens-form :access-token="accessTokensStore.accessToken"></access-tokens-form>
    </div>
</template>

<script lang="ts" setup>
import AccessTokensForm from './AccessTokensForm.vue';
import { useAccessTokensStore } from './accessTokensStore';

const accessTokensStore = useAccessTokensStore();

await accessTokensStore.initAccessToken();
</script>
