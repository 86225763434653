<template>
    <div class="areas-scope">
        <v-autocomplete
            v-model="modelAreas"
            v-model:search-input="search"
            :items="areasStore.areas"
            :placeholder="t('messages.resources.searchArea')"
            append-icon="search"
            chips
            clearable
            color="primary"
            data-id="NameAutoComplete"
            deletable-chips
            item-title="name"
            item-value="id"
            multiple
            return-object
            @change="search = ''"
        >
            <template #item="{ item: area }: { item: any }">
                <span :data-id="`autocomplete-${area.id}`">{{ `${area.name} (${area.id})` }}</span>
            </template>
        </v-autocomplete>
    </div>
</template>

<script lang="ts" setup>
import { FormSteps, Receiver } from '../messagesModels';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAreasStore } from '@/Features/Areas/areasStore';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();
const areasStore = useAreasStore();
const { t } = useI18n();

const modelAreas = ref<Receiver[]>([]);
const search = ref<null | string>(null);

messagesStore.selectedFormItem = [];
if (areasStore.areas.length === 0) {
    await areasStore.loadAreas();
}
const scopes = messagesStore.formSummary[FormSteps.Scope]?.selectedScopes;
if (scopes?.length) {
    modelAreas.value = scopes;
}

watch(modelAreas, val => {
    if (!val) return;
    const selectedAreas = val.map((x: any) => {
        return getReceiverFormat(x);
    });

    messagesStore.setSummaryScopes(selectedAreas);
});

function getReceiverFormat(data: any) {
    return {
        id: data.id,
        name: data.name
    } as Receiver;
}
</script>
