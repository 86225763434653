<template>
    <div class="stops-summary">
        <div class="stops-title">
            {{ t('messages.resources.stops', 'Holdeplass') }}
        </div>
        <div class="selected-stops">
            <div v-for="stop in stops" :key="stop.id" class="stop">
                <img src="@/assets/busstop.svg" alt="Bus stop" />
                <span>{{ stop.name }}</span>
                <img class="platform-side" src="@/assets/platformA.svg" alt="Platform A" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Receiver } from '../../messagesModels';
import { useI18n } from 'vue-i18n';

interface Props {
    stops: Array<Receiver>;
}
defineProps<Props>();
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.stops-title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 16px;
    display: flex;
    justify-content: space-between;
}

.selected-stops {
    .stop {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        img {
            height: 20px;
        }
        span {
            font-size: 14px;
            font-weight: bold;
        }

        .platform-side {
            height: 17px;
        }
    }
}
</style>
