<template>
    <div class="driversHandbooks">
        <v-main>
            <v-row>
                <v-col>
                    <v-row class="controls">
                        <v-btn class="white--text" color="primary" data-id="AddNewButton" @click="createDriversHandbook()">
                            {{ t('shared.resources.common.addNew') }}
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="driversHandbooksStore.driversHandbooks"
                        :items-per-page="-1"
                        data-id="DataTable"
                    >
                        <template #[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="{ props }">
                                    <v-icon
                                        class="mr-2"
                                        data-id="EditIcon"
                                        style="outline: none"
                                        v-bind="props"
                                        icon="mdi-pencil"
                                        @click="editDriversHandbook(item.id)"
                                    ></v-icon>
                                </template>
                                <span>
                                    {{ t('shared.resources.common.edit') }}
                                </span>
                            </v-tooltip>
                        </template>
                        <template #bottom></template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useDriversHandbooksStore } from './driversHandbooksStore';

const driversHandbooksStore = useDriversHandbooksStore();
const { t } = useI18n();
const router = useRouter();

const headers = computed(() => [
    {
        title: t('driversHandbooks.resources.name'),
        key: 'name'
    },
    {
        title: t('driversHandbooks.resources.sortOrder'),
        key: 'sortOrder'
    },
    {
        title: t('shared.resources.common.edit'),
        key: 'action'
    }
]);

await driversHandbooksStore.loadDriverHandbooks();

function editDriversHandbook(item: number) {
    router.push({ path: `/drivershandbooks/${item}` });
}

function createDriversHandbook() {
    router.push({ name: 'DriverHandbooksCreate' });
}
</script>

<style lang="scss" scoped>
.controls {
    justify-content: flex-end;
    margin: 12px 0;
}
</style>
