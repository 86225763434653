<template>
    <div class="choices-inputs">
        <div v-if="hasInputs" class="inputs">
            <input-form v-for="(input, i) in inputTemplates" :key="i" :item="input" @input="handleInput" />
        </div>
        <div v-if="choiceTemplates" class="choices">
            <v-radio-group v-model="choices">
                <v-radio
                    v-for="(choice, i) in choiceTemplates"
                    :key="i"
                    :data-id="`Choice${choice.name}`"
                    :label="choice.label"
                    :value="choice.name"
                    class="choices"
                ></v-radio>
            </v-radio-group>
        </div>
    </div>
</template>

<script lang="ts" setup>
import InputForm from './InputForm.vue';
import { computed, ref, watch } from 'vue';
import { FormSteps } from '../messagesModels';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();

const choices = ref('');
const inputModels = ref<any>(null);
const choiceGroup = ref<any>(null);

const reasonStep = computed(() => messagesStore.formSummary[FormSteps.Reason]);
const inputTemplates = computed(() => reasonStep.value.inputTemplates);
const hasInputs = computed(() => reasonStep.value.inputTemplates?.length);
const choiceTemplates = computed(() => reasonStep.value.choiceTemplates);

watch(
    inputModels,
    () => messagesStore.setFormSummaryByCurrentStep({ ...messagesStore.formSummary[FormSteps.Reason], input: inputModels.value }),
    {
        deep: true
    }
);
watch(choices, () =>
    messagesStore.setFormSummaryByCurrentStep({
        ...messagesStore.formSummary[FormSteps.Reason],
        choice: { [choiceGroup.value]: choices.value }
    })
);

inputTemplates.value.forEach((item: any) => {
    inputModels.value = { ...inputModels.value, [item.name]: null };
});

const choiceTempl = messagesStore.formSummary[FormSteps.Reason].choiceTemplates;
if (choiceTempl?.length) {
    choiceGroup.value = choiceTempl[0].group;
}
const existingChoice = messagesStore.formSummary[FormSteps.Reason].choice;
if (existingChoice?.[choiceGroup.value]) {
    choices.value = existingChoice[choiceGroup.value];
}

function handleInput(data: any) {
    inputModels.value[data.name] = data.inputType === 'Number' ? Number(data.value) : data.value;
}
</script>

<style lang="scss">
.choices-inputs {
    display: grid;
    grid-template-columns: 50% 50%;

    .inputs {
        margin-top: 20px;

        .v-input {
            width: 250px;

            .v-input__slot {
                margin-bottom: 12px;
                margin-top: 4px;
            }

            .v-text-field__details {
                display: none;
            }
        }
    }

    .v-selection-control-group {
        display: flex;

        .v-radio.choices {
            background-color: #ededed;
            margin-bottom: 24px;
            display: flex;
            width: 250px;
            min-height: 64px;
            text-align: center;
            box-shadow: 1px 2px 4px rgb(0 0 0 / 15%);

            .v-label {
                color: #000000;
                opacity: 1;
                flex: 0 0 100%;
                justify-content: center;
            }

            &.v-selection-control--dirty {
                background-color: #3ec652;

                label {
                    color: #ffffff;
                }
            }

            .v-selection-control__wrapper {
                display: none;
            }
        }
    }
}
</style>
