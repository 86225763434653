export const MessagesRels = {
    getMessages: 'getMessages',
    getMessage: 'getMessage',
    createMessage: 'createMessage',
    updateMessage: 'updateMessage',
    publishMessage: 'publishMessage',
    unpublishMessage: 'unpublishMessage',
    publishMessageGroup: 'publishMessageGroup',
    getMessageCategories: 'getMessageCategoryTemplates',
    getMessageTemplates: 'getMessageTemplates',
    getServiceJourneys: 'getServiceJourneys'
};
