<template>
    <div class="lines-scope">
        <v-autocomplete
            v-model="modelLines"
            :search="search"
            :items="autocompleteLines"
            :placeholder="t('messages.resources.searchLine')"
            append-inner-icon="search"
            return-object
            multiple
            chips
            closable-chips
            clearable
            data-id="NameAutoComplete"
            @update:model-value="search = ''"
            @update:search="onSearchLines"
        >
            <template #item="{ item, props: itemProps }: { item: any, props: any }">
                <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.value}`"></v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script lang="ts" setup>
import { FormSteps, LineReceiver } from '@/Features/Messages/messagesModels';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { AutocompleteItem } from '@/shared/models';
import { useLinesStore } from '@/Features/Lines/linesStore';
import { Line } from '@/Features/Lines/linesModels';
import { toLineAutocompleteItem } from '@/Features/Lines/lineHelpers';
import { useMessagesStore } from '../messagesStore';

const messagesStore = useMessagesStore();
const linesStore = useLinesStore();
const { t } = useI18n();

const modelLines = ref<AutocompleteItem<Line>[]>([]);
const search = ref<string | undefined>();
const autocompleteLines = computed<AutocompleteItem<Line>[]>(() => linesStore.linesWithAll.map(x => toLineAutocompleteItem(x)));

messagesStore.selectedFormItem = [];
if (autocompleteLines.value.length === 0) {
    await linesStore.loadLines();
}
const scopes = messagesStore.formSummary[FormSteps.Scope]?.selectedScopes;
if (scopes?.length) {
    scopes.forEach((scope: any) => {
        const selectedItem = autocompleteLines.value.find(x => {
            return x.data.privateCode === scope.id;
        });
        if (selectedItem) {
            modelLines.value.push(selectedItem);
        }
    });
}

watch(modelLines, (val: AutocompleteItem<Line>[]) => {
    let selectedArray: Array<LineReceiver>;
    const last = val[val.length - 1];
    const lastSelected = last?.data;
    if (lastSelected && lastSelected.id === 0 && val.length > 1) {
        // if there are individual lines selected, clear out selection when all lines selected
        selectedArray = [
            {
                name: lastSelected.name,
                id: lastSelected.privateCode,
                transportType: lastSelected.transportType.toString()
            }
        ];
        modelLines.value = [last];
    } else {
        const selectedLines = val.map(x => {
            return {
                name: x.data.name,
                id: x.data.privateCode,
                transportType: x.data.transportType.toString()
            };
        });

        if (selectedLines.length && selectedLines[0].id === '0' && val.length > 1) {
            // remove all lines, if other lines are selected
            selectedLines.shift();
            modelLines.value.shift();
        }
        selectedArray = selectedLines;
    }

    messagesStore.setSummaryScopes(selectedArray);
});

function onSearchLines(query: string) {
    if (!query) {
        return;
    }
    search.value = query;
}
</script>
