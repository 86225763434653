import { Link, ListResource, Resource } from '@/shared/models';
import { defineStore } from 'pinia';
import { DriverHandbook, DriverHandbookListItem } from './driversHandbooksModels';
import { getLink } from '@/shared/LinkHelper';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import { DriversHandbooksRels } from './rels';

export const useDriversHandbooksStore = defineStore('driversHandbooks', {
    state: () => ({
        driversHandbooks: new Array<DriverHandbookListItem>(),
        driversHandbook: {} as DriverHandbook,
        links: new Array<Link>()
    }),
    actions: {
        async loadDriverHandbooks() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(DriversHandbooksRels.getDriversHandbooks);

            const response = await axios.requestByLink<ListResource<DriverHandbookListItem>>(link);
            this.driversHandbooks = response.data.data;
            this.links = response.data.links;
        },
        async loadDriversHandbook(id: number) {
            const axios = useAxios();

            if (this.driversHandbooks.length === 0) {
                await this.loadDriverHandbooks();
            }

            const item = this.driversHandbooks.find(x => x.id === id);
            if (!item) throw Error(`Can't find drivers handbook by id: ${id}`);

            const link = getLink(DriversHandbooksRels.getDriversHandbook, item.links);

            const response = await axios.requestByLink<Resource<DriverHandbook>>(link);

            this.driversHandbook = response.data.data ?? ({} as DriverHandbook);
            this.links = response.data.links;
        },
        async initNewDriversHandbook() {
            if (this.driversHandbooks.length === 0) {
                await this.loadDriverHandbooks();
            }

            this.driversHandbook = {} as DriverHandbook;
        },
        async updateDriversHandbook() {
            const axios = useAxios();
            const link = getLink(DriversHandbooksRels.updateDriversHandbook, this.links);

            await axios.requestByLink(link, { data: this.driversHandbook });
        },
        async deleteDriversHandbook() {
            const axios = useAxios();
            const link = getLink(DriversHandbooksRels.deleteDriversHandbook, this.links);

            await axios.requestByLink(link);
        },
        async createDriversHandbook() {
            const axios = useAxios();

            const link = getLink(DriversHandbooksRels.createDriversHandbook, this.links);
            await axios.requestByLink(link, { data: this.driversHandbook });
        }
    }
});
