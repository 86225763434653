<template>
    <v-skeleton-loader v-if="loading" :transition-group="'fade-transition'" type="paragraph"></v-skeleton-loader>
    <div v-else class="page-container">
        <div class="page-content">
            <departure-scope
                v-model="message.receivers.departures"
                :lines="lines"
                :service-journeys="serviceJourneys"
                :loading="loadingTable"
                @update-operating-day="onUpdateOperatingDay"
                @update-line="onUpdateLine"
            ></departure-scope>
        </div>
        <div class="page-sidebar">
            <message-summary
                :title="t('messages.resources.newCancellation', 'Ny innstilling')"
                :submit-button-text="t('messages.resources.cancelDepartures', 'Innstill avgang')"
                :is-submit-visible="isDataSelected"
                :submit-button-disabled="!isValid"
                @submit="publish"
                @back="back"
                ><departure-scope-summary
                    :departures="message.receivers.departures"
                    @remove-departure="onRemoveDeparture"
                ></departure-scope-summary>
                <message-reason-summary
                    v-if="isDataSelected"
                    :message-templates="selectedMessageTemplates"
                    @update-reason="onUpdateReason"
                    @update-reason-text="onUpdateReasonText"
                ></message-reason-summary>
            </message-summary>
        </div>
    </div>
</template>

<script lang="ts" setup>
import MessageSummary from './MessageSummary.vue';
import MessageReasonSummary from './MessageReasonSummary.vue';
import DepartureScope from './Scopes/DeparturesScope.vue';
import DepartureScopeSummary from './Scopes/DepartureScopeSummary.vue';
import { useMessagesStore } from '../messagesStore';
import { storeToRefs } from 'pinia';
import { useLinesStore } from '@/Features/Lines/linesStore';
import { useI18n } from 'vue-i18n';
import { FormatDateISO } from '@/shared/DateFormatHelpers';
import { computed, ref } from 'vue';
import { Line } from '@/Features/Lines/linesModels';
import { CancellationCategoryName, DepartureReceiver, MessageTemplate } from '../messagesModels';
import { departureReceiversEqual } from '../helpers';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router';

const { t } = useI18n();
const router = useRouter();

const messagesStore = useMessagesStore();
const linesStore = useLinesStore();

const { message, serviceJourneys, messageTemplates } = storeToRefs(messagesStore);
const { lines } = storeToRefs(linesStore);

const loadingTable = ref(false);
const loading = ref(false);

const selectedOperatingDay = ref(FormatDateISO(new Date()));
const selectedLine = ref<Line | null>();

const selectedMessageTemplates = computed(() => messageTemplates.value.filter(x => x.categoryName == CancellationCategoryName));
const isDataSelected = computed(() => message.value.receivers.departures.length > 0);
const isValid = computed<boolean>(() => validate());

await load();

async function load() {
    if (messagesStore.categoryTemplates.length < 1) await messagesStore.loadMessageCategories();
    if (messagesStore.messageTemplates.length < 1) await messagesStore.loadMessageTemplates();
    if (linesStore.lines.length < 1) await linesStore.loadLines();
    await messagesStore.initMessage();
    await loadServiceJourneys();
}

async function onUpdateOperatingDay(newOperatingDay: string) {
    selectedOperatingDay.value = newOperatingDay;
    await loadServiceJourneys();
}

async function onUpdateLine(newLine: Line | null) {
    selectedLine.value = newLine;
    await loadServiceJourneys();
}

async function onRemoveDeparture(oldDeparture: DepartureReceiver) {
    const index = message.value.receivers.departures.findIndex(x => departureReceiversEqual(x, oldDeparture));
    if (index > -1) {
        message.value.receivers.departures.splice(index, 1);
    }
}

async function loadServiceJourneys() {
    if (selectedOperatingDay.value && selectedLine.value) {
        loadingTable.value = true;
        await messagesStore.loadServiceJourneys({ date: selectedOperatingDay.value, linePrivateCode: selectedLine.value.privateCode });
        loadingTable.value = false;
    } else {
        messagesStore.serviceJourneys = [];
    }
}

async function publish() {
    loading.value = true;
    try {
        message.value.shouldPublish = true;
        await messagesStore.createMessage();
        await router.push({ name: routeNames.deviationMessages });
    } catch {
        loading.value = false;
    }
}

function validate(): boolean {
    const theMessage = message.value;
    const templateId = theMessage.structuredMessage.templateId;
    const messageTemplate = messageTemplates.value.find(x => x.id == templateId);
    const isBodyValid = messageTemplate?.editable ? !!theMessage.body && theMessage.body.trim() != '' : true;
    return theMessage.receivers.departures.length > 0 && !!messageTemplate && isBodyValid;
}

function back() {
    router.push({ name: routeNames.deviationMessages });
}

function onUpdateReason(messageTemplate?: MessageTemplate) {
    message.value.structuredMessage.templateId = messageTemplate?.id ?? '';
}

function onUpdateReasonText(text: string) {
    message.value.body = text;
}
</script>

<style lang="scss" scoped>
.page-container {
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.page-content {
    flex: 1;
    min-width: 600px;
}

.page-sidebar {
    flex: 0 0 360px;
}
</style>
