<template>
    <div class="rangeSelector">
        <v-btn
            v-for="(item, j) in items"
            :key="j"
            :class="{
                selected: selectedRange.includes(j),
                ranged: hoverRange.includes(j)
            }"
            class="range-item"
            data-id="AddButton"
            @click="selection($event, item, j)"
            @mouseover="setHoverRange(j)"
        >
            {{ getDepartureTime(item?.departureAt) }}
        </v-btn>
    </div>
</template>

<script lang="ts" setup>
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';

const { t } = useI18n();

const props = defineProps({
    selectedDepartures: Array,
    items: Array<any>,
    deselected: Object,
    day: String,
    line: Object
});
const emit = defineEmits(['selected', 'removed']);

const selectedRange = ref<any[]>([]);
const rangeStart = ref(-1);
const hoverRange = ref<any[]>([]);
const activeSelection = ref(false);
const deselected = computed(() => props.deselected);

if (props.selectedDepartures?.length) {
    props.selectedDepartures?.forEach((departure: any) => {
        checkIfDepartureSelected(departure);
    });
}

watch(deselected, (val: any) => {
    const des = props.items?.find((item: any) => {
        return item.departureAt === val.departureTime && item.tripId === val.id;
    });

    if (des) {
        const index = props.items?.indexOf(des) ?? -1;
        deselect(des, index);
    }
});

function getDepartureTime(time: string) {
    return ToLocalDate(time, 'time');
}

function setHoverRange(index: number) {
    if (rangeStart.value > -1) {
        const rangeDirection = rangeStart.value - index;
        const hoveredRange = [] as any;
        if (rangeDirection <= 0) {
            for (let i = rangeStart.value; i <= index; i++) {
                hoveredRange.push(i);
            }
        } else {
            for (let i = rangeStart.value; i >= index; i--) {
                hoveredRange.push(i);
            }
        }
        hoverRange.value = hoveredRange;
    } else {
        hoverRange.value = [];
    }
}

function selection(e: PointerEvent, item: any, index: number) {
    if (e.shiftKey || activeSelection.value) {
        activeSelection.value = !activeSelection.value;
        selectDeselectRange(index);
    } else {
        rangeStart.value = -1;
        selectDeselect(item, index);
    }
}

function select(item: any, index: number) {
    if (!selectedRange.value.includes(index)) {
        selectedRange.value.push(index);
    }
    emit('selected', item);
}

function deselect(item: any, index: number) {
    const itemToRemove = selectedRange.value.indexOf(index);
    if (itemToRemove !== -1) {
        selectedRange.value.splice(itemToRemove, 1);
        item = {
            ...item,
            operatingDay: props.day
        };
        emit('removed', item);
    }
}

function selectDeselect(item: any, index: number) {
    const selectedCheck = selectedRange.value.indexOf(index);
    if (selectedCheck > -1) {
        deselect(item, index);
    } else {
        select(item, index);
    }
}

function selectDeselectRange(index: number) {
    if (rangeStart.value <= -1) {
        rangeStart.value = index;
        select(props.items?.[index], index);
        return;
    }

    // flip range start and end, if selected backwards
    const start = rangeStart.value < index ? rangeStart.value : index;
    const end = rangeStart.value < index ? index : rangeStart.value;

    if (selectedRange.value.includes(start) && selectedRange.value.includes(end)) {
        for (let i = start; i <= end; i++) {
            deselect(props.items?.[i], i);
        }
        return;
    }

    for (let i = start; i <= end; i++) {
        select(props.items?.[i], i);
    }

    rangeStart.value = -1;
}

function checkIfDepartureSelected(departure: any) {
    if (
        departure.operatingDay.substring(0, 10) === props.day &&
        departure.name.indexOf(`${t('messages.resources.line')} ${props.line?.name}`) > -1
    ) {
        const sel =
            props.items?.findIndex((item: any) => {
                return item.tripId === departure.id;
            }) ?? -1;

        if (sel > -1) {
            selectedRange.value.push(sel);
        }
    }
}
</script>

<style lang="scss" scoped>
.range-item {
    margin-top: 10px;
}

.rangeSelector {
    margin: 0 auto;
    max-width: 262px;

    .v-btn {
        width: 70px;
        height: 34px !important;
        font-weight: 500;
        font-size: 18px;

        &.selected {
            background: #3ec652 !important;
            color: #ffffff !important;
        }

        &.ranged {
            background: #3ec652 !important;
            color: #ffffff !important;
            opacity: 0.6;
        }
    }
}
</style>
