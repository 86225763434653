<template>
    <div class="message-form">
        <div class="form-header">Ny melding</div>
        <v-skeleton-loader v-if="loading" :transition-group="'fade-transition'" type="card"></v-skeleton-loader>
        <div v-if="!loading" class="form-content">
            <form-navigation-bar
                :current="messagesStore.currentStep"
                :steps="steps"
                class="nav-bar"
                @form-step-changed="navigateFromHeader"
            />
            <div class="step-content">
                <div class="step-title">
                    {{ t(`messages.resources.${steps[messagesStore.currentStep]}`) }}
                    <span v-if="messagesStore.instepContent && messagesStore.instepContent.subtitle"> / {{ getSubtitle() }}</span>
                </div>
                <form-content />
            </div>
            <div class="form-controls">
                <div>
                    <v-btn color="primary" data-id="CancelButton" variant="outlined" @click="cancel()">
                        {{ t('shared.resources.common.cancel') }}
                    </v-btn>
                    <v-btn
                        v-if="messagesStore.currentStep > FormSteps.Categories"
                        color="primary"
                        data-id="BackButton"
                        variant="outlined"
                        @click="prev()"
                    >
                        {{ t('shared.resources.common.back') }}
                    </v-btn>
                </div>
                <v-btn
                    v-if="messagesStore.currentStep < FormSteps.Publishing"
                    :disabled="disableNext"
                    class="white--text"
                    color="primary"
                    data-id="NextButton"
                    @click="next()"
                >
                    {{ t('shared.resources.common.next') }}
                </v-btn>
                <v-btn
                    v-if="messagesStore.currentStep === FormSteps.Publishing"
                    class="white--text"
                    color="primary"
                    data-id="SavePublishButton"
                    @click="publishNewMessage"
                >
                    {{ t('messages.resources.newMessageSaveAndPublish') }}
                </v-btn>
            </div>
        </div>
        <div class="form-summary">
            <form-summary />
        </div>
    </div>
</template>

<script lang="ts" setup>
import FormNavigationBar from './FormNavigationBar.vue';
import FormSummary from './FormSummary.vue';
import FormContent from './FormContent.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { FormSteps, Message, MessageFormInstepContent, MessageStatus, Receivers, Scopes } from './messagesModels';
import { useMessagesStore } from './messagesStore';

const messagesStore = useMessagesStore();
const { t } = useI18n();
const router = useRouter();

const steps = ['category', 'reason', 'scope', 'publishing'];
const takenSteps = ref<Array<number>>([]);
const loading = ref(false);

const disableNext = computed(() => {
    if (messagesStore.instepContent?.contentType) {
        switch (messagesStore.instepContent.contentType) {
            case 'choiceOrInput':
                if (messagesStore.formSummary[FormSteps.Reason]?.choice) return false;
                break;
            case 'editor':
                if (messagesStore.formMessageText) return false;
                break;
            case Scopes.Departures:
            case Scopes.Lines:
            case Scopes.Stops:
            case Scopes.Areas:
            case Scopes.Companies:
            case Scopes.Drivers:
                if (messagesStore.formSummary[FormSteps.Scope]?.selectedScopes.length) return false;
                break;
            default:
                return true;
        }
    } else if (messagesStore.formSummary[messagesStore.currentStep]) {
        return false;
    }
    return true;
});

await messagesStore.initMessage();
if (messagesStore.categoryTemplates.length < 1) await messagesStore.loadMessageCategories();
if (messagesStore.messageTemplates.length < 1) await messagesStore.loadMessageTemplates();

function next() {
    messagesStore.instepContent = {} as MessageFormInstepContent;
    const step = messagesStore.currentStep + 1;
    const choices = messagesStore.selectedFormItem?.choiceTemplates;
    const inputs = messagesStore.selectedFormItem?.inputTemplates;

    // Covering instep navigation
    switch (messagesStore.currentStep) {
        case FormSteps.Reason:
            if ((choices && choices.length > 0) || (inputs && inputs.length > 0)) {
                messagesStore.instepContent = { subtitle: messagesStore.selectedFormItem.name, contentType: 'choiceOrInput' };
                setNextInstep();
                return;
            }
            if (messagesStore.selectedFormItem?.editable) {
                messagesStore.instepContent = { subtitle: messagesStore.selectedFormItem.name, contentType: 'editor' };
                setNextInstep();
                return;
            }
            break;
        case FormSteps.Scope:
            if (typeof messagesStore.selectedFormItem === 'string' && messagesStore.selectedFormItem !== 'Global') {
                messagesStore.instepContent = { subtitle: messagesStore.selectedFormItem, contentType: messagesStore.selectedFormItem };
                setNextInstep();
                return;
            }
            break;
        case FormSteps.Categories:
            if (messagesStore.categoryMessageTemplates.length === 1) {
                setInfoMessageEditor();
                // Not returning because it needs default full step navigation
            }
    }
    // Default full step navigation
    takenSteps.value.push(step);
    messagesStore.handleFormStepChange(step);
    messagesStore.selectedFormItem = null;
}

function setNextInstep() {
    takenSteps.value.push(messagesStore.currentStep + FormSteps.InstepOffset);
    messagesStore.selectedFormItem = null;
}

function setInfoMessageEditor() {
    if (messagesStore.categoryMessageTemplates[0].editable) {
        // Setting only available message template for those categories
        messagesStore.setFormSummaryByStep({ item: messagesStore.categoryMessageTemplates[0], step: FormSteps.Reason });
        messagesStore.instepContent = { subtitle: undefined, contentType: 'editor' };
    }
}

function prev() {
    // Clear any lingering instep content
    messagesStore.instepContent = {} as MessageFormInstepContent;

    const lastStep = takenSteps.value.pop();
    if (!lastStep) return;
    const current = takenSteps.value[takenSteps.value.length - 1];
    const scope = messagesStore.formSummary[FormSteps.Scope]?.scope;

    switch (current) {
        case FormSteps.Reason:
            // Information and driver message edit form
            if (messagesStore.categoryMessageTemplates.length === 1 && messagesStore.categoryMessageTemplates[0].editable) {
                messagesStore.instepContent = { subtitle: undefined, contentType: 'editor' };
            }
            break;
        case FormSteps.Reason + FormSteps.InstepOffset:
            if (messagesStore.formSummary[FormSteps.Reason]?.name === 'Other') {
                messagesStore.instepContent = { subtitle: 'Other', contentType: 'editor' };
            } else if (
                messagesStore.formSummary[FormSteps.Reason]?.choiceTemplates ||
                messagesStore.formSummary[FormSteps.Reason]?.inputTemplates
            ) {
                messagesStore.instepContent = {
                    subtitle: messagesStore.formSummary[FormSteps.Reason].name,
                    contentType: 'choiceOrInput'
                };
            }
            break;
        case FormSteps.Scope + FormSteps.InstepOffset:
            // scopes with multiple choice
            messagesStore.instepContent = { subtitle: scope, contentType: scope };
            break;
    }

    if (lastStep % 1 == 0) {
        // handling full step change without special instep content
        messagesStore.handleFormStepChange(lastStep - 1);
        messagesStore.setFormStepReset(lastStep);
    } else {
        setPartialStepReset(Math.floor(lastStep));
    }
}

function setPartialStepReset(lastStep: number) {
    const scopeName = messagesStore.formSummary[FormSteps.Scope]?.scope;

    switch (lastStep) {
        case FormSteps.Reason:
            messagesStore.formSummary[FormSteps.Reason].choice = null;
            messagesStore.formSummary[FormSteps.Reason].input = null;
            messagesStore.selectedFormItem = messagesStore.formSummary[FormSteps.Reason];
            break;
        case FormSteps.Scope:
            messagesStore.setFormSummaryByStep({
                item: { scope: scopeName, selectedScopes: [] },
                step: FormSteps.Scope
            });
            messagesStore.selectedFormItem = scopeName;
            break;
    }
}

function getSubtitle() {
    if (messagesStore.currentStep === FormSteps.Reason) {
        return messagesStore.formSummary[FormSteps.Reason]?.label;
    } else {
        const subtitle = messagesStore.instepContent?.subtitle ?? '';
        const lowerCaseCh = subtitle.charAt(0).toLowerCase() + subtitle.slice(1);
        return t(`messages.resources.${lowerCaseCh}`);
    }
}

function navigateFromHeader(step: number) {
    messagesStore.instepContent = {} as MessageFormInstepContent;
    const prevStepInfo = messagesStore.formSummary[FormSteps.Reason];
    messagesStore.resetForm(step);
    if (step === FormSteps.Reason) {
        if (messagesStore.categoryMessageTemplates.length === 1 && messagesStore.categoryMessageTemplates[0].editable) {
            // For driver and info message Reason step has to be preselected
            messagesStore.setFormSummaryByStep({ item: prevStepInfo, step: step });
            messagesStore.instepContent = { subtitle: undefined, contentType: 'editor' };
        }
    }
    if (step === FormSteps.Scope) {
        const scopes = messagesStore.formSummary[FormSteps.Reason].messageScopes;
        if (scopes.length === 1) {
            // Preset scope if there is only one option
            messagesStore.instepContent = { subtitle: scopes[0], contentType: scopes[0] };
            messagesStore.setFormSummaryByStep({ item: { scope: scopes[0], selectedScopes: [] }, step: step });
        }
    }
    const stepIndex = takenSteps.value.indexOf(step);
    takenSteps.value = takenSteps.value.slice(0, stepIndex + 1);
}

function publishNewMessage() {
    const message = {
        pushNotifications: null as boolean | null,
        receivers: {},
        channels: messagesStore.formSummary[FormSteps.Categories].name === 'MessageToDriver' ? ['TicketingMachine'] : ['General'],
        startPublish: messagesStore.formSummary[FormSteps.Publishing].startPublish,
        endPublish: messagesStore.formSummary[FormSteps.Publishing].endPublish,
        subject: '',
        status: MessageStatus.Created,
        hasChangedSincePublish: false,
        structuredMessage: {
            templateId: messagesStore.formSummary[FormSteps.Reason].id,
            input: {},
            choice: messagesStore.formSummary[FormSteps.Reason].choice || {}
        }
    } as Message;
    if (messagesStore.formSummary[FormSteps.Reason].message) {
        message.body = messagesStore.formSummary[FormSteps.Reason].message;
    }
    if (messagesStore.formSummary[FormSteps.Reason].pushAvailable) {
        message.pushNotifications = messagesStore.formSummary[FormSteps.Publishing].pushNotifications;
    }
    if (messagesStore.formSummary[FormSteps.Reason].input) {
        const inputs = messagesStore.formSummary[FormSteps.Reason].input;
        for (const input in inputs) {
            if (!inputs[input]) inputs[input] = 0;
        }
        message.structuredMessage.input = inputs;
    }

    const receivers = messagesStore.formSummary[FormSteps.Scope];
    message.receivers = { scopes: [receivers.scope], [receivers.scope.toLowerCase()]: receivers.selectedScopes } as unknown as Receivers;
    messagesStore.message = message;
    messagesStore.resetForm(0);
    createNewMessage();
}

async function createNewMessage() {
    loading.value = true;
    //for new message to publish we need to save draft first
    try {
        const groupId = await messagesStore.createMessage();
        await messagesStore.publishMessageGroup(groupId);
        await router.push({ path: '/messages' });
    } finally {
        loading.value = false;
    }
}

function cancel() {
    messagesStore.resetForm(0);
    router.go(-1);
}
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
    padding: 12px;
}

.message-form {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 90px 1fr;
    grid-template-areas:
        'header header'
        'content summary';
    margin: 24px;
    height: 100%;
    @media screen and (max-width: 1920px) {
        height: 92%;
    }

    .form-header {
        grid-area: header;
        background-color: #f3f3f3;
        padding: 0 50px;
        font-size: 30px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    .form-content {
        grid-area: content;
        padding: 10px 50px 10px 20px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50px 1fr 60px;

        .nav-bar {
            grid-row: 1 / 2;
            padding-left: 20px;
        }

        .step-title {
            padding-top: 40px;
            padding-bottom: 10px;
            font-weight: 700;
            font-size: 25px;
            line-height: 33px;
        }

        .step-content {
            grid-row: 2 / 3;
            min-height: 450px;
        }

        .form-controls {
            grid-row: 3 / 4;
            display: flex;
            justify-content: space-between;
        }
    }

    .form-summary {
        grid-area: summary;
        background-color: #dedede;
    }
}
</style>
