<template>
    <div class="summary-container">
        <div>
            <v-select
                v-model="selectedReason"
                :items="reasonItems"
                :label="t('messages.resources.reason', 'Velg avvikstype fra listen')"
                return-object
                color="primary"
                variant="outlined"
                persistent-placeholder
                data-id="ReasonSelector"
            ></v-select>
        </div>
        <div v-if="hasEditor">
            <editor
                v-model="reasonText"
                :init="{
                    entity_encoding: 'raw',
                    height: 270,
                    menubar: false,
                    language: 'nb_NO',
                    language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/nb_NO.js',
                    plugins: ['lists link'],
                    toolbar: 'bold italic | link | help',
                    content_css: '/tinymce.css',
                    placeholder: t('messages.resources.reasonTextPlaceholder', 'Skriv inn årsak til innstilling her for loggføring.')
                }"
                data-id="BodyEditor"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import { AutocompleteItem } from '@/shared/models';
import { MessageTemplate } from '../messagesModels';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface Props {
    messageTemplates: MessageTemplate[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
    (e: 'updateReason', messageTemplate?: MessageTemplate);
    (e: 'updateReasonText', text: string);
}>();

const selectedReason = ref<AutocompleteItem<MessageTemplate>>();
const reasonText = ref<string>('');

const reasonItems = computed(() => props.messageTemplates.map(x => toAutocompleteItem(x)));
const hasEditor = computed(() => selectedReason.value?.data.editable ?? false);

watch(selectedReason, (newValue?: AutocompleteItem<MessageTemplate>) => {
    emit('updateReason', newValue?.data);
});

watch(reasonText, (newValue: string) => {
    emit('updateReasonText', newValue);
});

function toAutocompleteItem(messageTemplate: MessageTemplate) {
    return { title: messageTemplate.label, value: messageTemplate.id, data: messageTemplate };
}
</script>

<style lang="scss" scoped>
.summary-container {
    padding-top: 16px;
}
</style>

<style lang="scss">
@import '@/shared/variables.scss';

.tox {
    .tox-toolbar-overlord {
        background-color: $lightGrey !important;
    }

    .tox-statusbar {
        background-color: $lightGrey !important;
    }

    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
        background-color: $lightGrey !important;
    }
}
</style>
