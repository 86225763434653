<template>
    <div class="selected-departures">
        <div v-for="(group, i) in departureGroups" :key="i" class="departures-group">
            <div class="summary-title">{{ group[0].groupName }}</div>
            <div
                v-for="(departure, j) in sortDepartures(group)"
                :key="j"
                :disabled="disabled"
                class="departure-btn"
                data-id="DeleteDiv"
                @click="removeDeparture(departure)"
            >
                {{ getDepartureTime(departure.departureTime) }}
                <v-icon icon="mdi-close-circle-outline" size="small"></v-icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { computed } from 'vue';

const props = defineProps({
    disabled: Boolean,
    selectedDepartures: Array
});
const emit = defineEmits(['removedFromSummary']);

const departureGroups = computed<any>(() => {
    return props.selectedDepartures?.reduce(function (groups: any, departure: any) {
        groups[departure.groupName] = groups[departure.groupName] || [];
        groups[departure.groupName].push(departure);
        return groups;
    }, {});
});

function getDepartureTime(time: string) {
    return ToLocalDate(time, 'time');
}

function removeDeparture(departure: any) {
    if (props.disabled) return;
    emit('removedFromSummary', departure);
}

function sortDepartures(departures: Array<Object>) {
    departures.sort((a: any, b: any) => {
        if (new Date(a.departureTime) > new Date(b.departureTime)) return 1;
        if (new Date(a.departureTime) < new Date(b.departureTime)) return -1;
        return 0;
    });
    return departures as any;
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.selected-departures {
    padding: 10px 16px 0;

    .departures-group {
        border-bottom: thin rgba(0, 0, 0, 0.2) solid;

        &:last-child {
            border: none;
        }
    }
}

.departure-btn {
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    margin: 2px;
    padding: 2px 25px 2px 4px;
    text-align: center;
    background: transparent;
    border: none;
    color: #000000;
    cursor: pointer;

    i.v-icon {
        color: #000000;
        position: absolute;
        width: 12px;
        right: 8px;
        top: 6px;
        bottom: 0;
        opacity: 0.7;
        font-size: 16px;
        line-height: 30px;
    }
}

.summary-title {
    color: rgba(0, 0, 0, 0.54);
    padding: 5px 0;
}
</style>
